import styled from 'styled-components';

import { MedicalQna } from 'constants/Medical';
import QeustionItem from './QeustionItem';
import { Title48 } from 'styles';

function QuestionList() {
  return (
    <Section>
      <TitleWrapper>
        <Title48>자주 묻는 질문</Title48>
      </TitleWrapper>
      <QeustionWrapper>
        {MedicalQna.map((value) => (
          <QeustionItem
            key={value.question}
            question={value.question}
            answer={value.answer}
          />
        ))}
      </QeustionWrapper>
    </Section>
  );
}

export default QuestionList;

const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 10rem 0.75rem;
  gap: 3rem;
  @media ${(props) => props.theme.tablet} {
    padding: 3.5rem 1.25rem;
    gap: 2rem;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
`;

const QeustionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;
