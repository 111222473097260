import RefundAplication from 'components/_common/RefundAplication';
import MedicalTaxRefundIntro from './MedicalTaxRefundIntro';
import MedicalTaxRefundTarget from './MedicalTaxRefundTarget';
import MedicalTaxRefundGuide from './MedicalTaxRefundGuide';

function RefundMedical() {
  return (
    <>
      <MedicalTaxRefundIntro />
      <MedicalTaxRefundTarget />
      <MedicalTaxRefundGuide />
      <RefundAplication />
    </>
  );
}

export default RefundMedical;
