import styled, { css, keyframes } from 'styled-components';

import ContactUsIcon from 'assets/medical/ContactUs.png';
import { useEffect, useState } from 'react';

function ContactButton() {
  const [isDisplay, setIsDisplay] = useState(false);

  const checkMobileScreen = () => window.innerWidth <= 1023;

  useEffect(() => {
    const handleScroll = () => {
      if (!checkMobileScreen()) {
        if (window.scrollY > 100) {
          setIsDisplay(true);
        } else {
          setIsDisplay(false);
        }
      }
    };

    if (checkMobileScreen()) {
      setIsDisplay(true);
    }

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', () => {
      if (checkMobileScreen()) {
        setIsDisplay(true);
      } else {
        handleScroll();
      }
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  const onClickMoveInquiry = () => {
    const target = document.querySelector('#inquiry');
    target?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <Wrapper isDisplay={isDisplay}>
      <Button onClick={onClickMoveInquiry}>
        <Icon src={ContactUsIcon} />
        서비스 가입 문의
      </Button>
    </Wrapper>
  );
}

export default ContactButton;

const fadeIn = keyframes`
  from {
    opacity: 0;
    bottom: 0;
  }
  to {
    opacity: 1;
    bottom: 5%;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    bottom: 5%;
  }
  to {
    opacity: 0;
    bottom: 0;
  }
`;

const Wrapper = styled.div<{ isDisplay: boolean }>`
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
  animation: ${(props) =>
    props.isDisplay
      ? css`
          ${fadeIn} 0.5s forwards
        `
      : css`
          ${fadeOut} 0.5s forwards
        `};
  ${(props) =>
    props.isDisplay
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
  @media ${(props) => props.theme.tablet} {
    display: flex;
    animation: none;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 17.3rem;
  border-radius: 62.5rem;
  background: linear-gradient(90deg, #246cf6 0%, #7ca7fa 100%);
  box-shadow: 3px 3px 12px 0px rgba(0, 0, 0, 0.3);
  padding: 0.75rem 4rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 150%;
  color: #f4f8ff;
  border-radius: 62.5rem;
  gap: 0.37rem;
`;

const Icon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;
