import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import selectProductIcon from 'assets/home/TaxfreeSmartSelect/selectProduct.png';
import talkIcon from 'assets/home/TaxfreeSmartSelect/talkIcon.png';
import FlexWrap from 'components/_common/FlexWrap';
import Icon from 'components/_common/Icon';
import Typography from 'components/_common/Typography';
import useDeviceCheck from 'hooks/useDeviceCheck';
import { useLocation } from 'react-router';

function TaxfreeSmartSelect() {
  const [isSelected, setIsSelected] = useState(true);
  const smartSelectHeight = useRef<number | undefined>(0);
  const { isMobile } = useDeviceCheck();

  const onScroll = async () => {
    if (!smartSelectHeight.current) return;
    const currsetScrollY = window.scrollY;
    if (
      smartSelectHeight.current < currsetScrollY + (isMobile ? 40 : -400) &&
      isSelected
    ) {
      await setIsSelected(false);
    }
  };

  useEffect(() => {
    smartSelectHeight.current =
      document.getElementById('taxfreeSmartSelect')?.offsetHeight;
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [isSelected]);

  return (
    <Wrapper id='taxfreeSmartSelect'>
      <Container>
        <TitleWrap>
          <MainTitle>
            똑똑한 <Typography color='#246CF6'>선택</Typography>, 현명한{' '}
            <Typography color='#246CF6'>쇼핑</Typography>
          </MainTitle>
          <SubTitle>
            Q. 같은 상품이 있다면, {isMobile && <br />}어떤 것을 구매하실
            건가요?
            <Icon
              imgUrl={talkIcon}
              width='1.25rem'
              height='1.25rem'
              margin='0 0 0.3rem'
            />
          </SubTitle>
        </TitleWrap>
        <SelectContainer>
          <SelectItem isSelected={isSelected}>
            <SelectImageContainer>
              <SelectImage src={selectProductIcon} />
            </SelectImageContainer>
            <SelectContentContainer>
              <TaxfreeAdjustContent isTaxfree>
                TAX REFUND 적용
              </TaxfreeAdjustContent>
              <TaxfreeProductContent>
                <Typography size='1.125rem' fontWeight='800' color='#666666'>
                  A. 모바일 손목시계
                </Typography>
                <PriceContainer alignItems='flex-end' gap='5px'>
                  <CancleText>110,000원</CancleText>
                  <Typography size='1.75rem' fontWeight='700' color='#E42939'>
                    100,000원
                  </Typography>
                </PriceContainer>
              </TaxfreeProductContent>
              <ButtonWrapper onClick={() => setIsSelected(false)}>
                <Typography size='1rem' fontWeight='500' color='#FFF'>
                  A 선택하기
                </Typography>
              </ButtonWrapper>
            </SelectContentContainer>
          </SelectItem>
          {!isMobile && isSelected && (
            <Typography
              size='1.rem'
              margin='0 2rem'
              fontWeight='700'
              color='#4D4D4D'
            >
              VS
            </Typography>
          )}
          <SelectItem isSelected={isSelected}>
            {!isMobile && (
              <SelectImageContainer>
                <SelectImage src={selectProductIcon} />
              </SelectImageContainer>
            )}

            <SelectContentContainer>
              <TaxfreeAdjustContent>TAX REFUND 미적용</TaxfreeAdjustContent>
              <TaxfreeProductContent>
                <Typography size='1.125rem' fontWeight='800' color='#666666'>
                  B. 모바일 손목시계
                </Typography>
                <Typography size='1.75rem' fontWeight='700' color='#333'>
                  110,000원
                </Typography>
              </TaxfreeProductContent>
              <ButtonWrapper disabled isDisable>
                <Typography size='1rem' fontWeight='500' color='#FFF'>
                  B 선택하기
                </Typography>
              </ButtonWrapper>
            </SelectContentContainer>
          </SelectItem>
          <SelectItem isSelected={!isSelected}>
            <SelectImageContainer>
              <SelectImage src={selectProductIcon} />
            </SelectImageContainer>
            <SelectContentContainer>
              <TaxfreeAdjustContent isTaxfree>
                TAX REFUND 적용
              </TaxfreeAdjustContent>
              <TaxfreeProductContent>
                <Typography size='1.125rem' fontWeight='700' color='#191919'>
                  A. 모바일 손목시계
                </Typography>

                <Typography
                  size='1rem'
                  fontWeight='400'
                  color='#333'
                  margin={isMobile ? '8px 0 0 0' : '0'}
                >
                  알뜰하고 현명한 선택하셨군요!
                  <br />
                  같은 상품이라도 할인가를 구매하는 건 {isMobile && <br />}
                  전세계가 동일합니다.
                  {!isMobile && <br />}
                  외국인 관광객도 {isMobile && <br />}이처럼 현명한 쇼핑을
                  합니다. {isMobile && <br />}매장에도 고객에도 좋은 TAX
                  REFUND로 외국인 관광객을 사로 잡으세요!
                </Typography>
              </TaxfreeProductContent>
            </SelectContentContainer>
          </SelectItem>
        </SelectContainer>
      </Container>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 9rem 7.75rem;
  @media ${(props) => props.theme.tablet} {
    padding: 3.75rem 1.25rem 7.2rem;
  }
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    background: linear-gradient(180deg, #f4f8ff -5.96%, #e3edff 62.21%);
  }
`;
const Container = styled(FlexWrap)`
  width: 100%;
  flex-direction: column;
`;

const TitleWrap = styled(FlexWrap)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const MainTitle = styled(FlexWrap)`
  color: #191919;
  font-size: 2rem;
  font-weight: 700;
  line-height: 150%;
  white-space: pre-wrap;
  margin-bottom: 1.75rem;
  @media ${(props) => props.theme.tablet} {
    margin-bottom: 0.44rem;
  }
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 1.69rem;
  }
`;
const SubTitle = styled(FlexWrap)`
  white-space: pre-wrap;
  align-items: center;
  gap: 0.62rem;
  font-size: 1.13rem;
  font-weight: 500;
  line-height: 150%;
  padding: 0.5rem 1.5rem;
  border: 1px solid #030303;
  border-radius: 0.75rem;
  background-color: #ffffff;
  margin-bottom: 2.25rem;
  @media ${(props) => props.theme.mobile} {
    align-items: flex-end;
    width: 100%;
  }
`;
const SelectContainer = styled(FlexWrap)`
  align-items: center;
  @media ${(props) => props.theme.mobile} {
    flex-direction: column;
    gap: 3rem;
    height: auto;
  }
`;
const SelectItem = styled(FlexWrap)<{ isSelected: boolean }>`
  flex: 1;
  border-radius: 1.25rem;
  flex-direction: column;
  box-shadow: 0px 0px 40px 0px #dde4ef;
  max-width: ${(props) => (props.isSelected ? 'auto' : '0px')};
  opacity: ${(props) => (props.isSelected ? 1 : 0)};
  display: ${(props) => (props.isSelected ? 'block' : 'none')};
  transition: opacity 1000ms ease-in;
  overflow-x: hidden;
  @media ${(props) => props.theme.bannerMobile} {
    width: 100vw;
    max-width: ${(props) => (props.isSelected ? 'auto' : '0px')};
    max-height: ${(props) => (props.isSelected ? 'auto' : '0px')};
    box-shadow: none;
    gap: 32px;
    padding: 0 16px;
  }
`;
const SelectImageContainer = styled(FlexWrap)`
  padding: 40px 0;
  justify-content: center;
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  background: linear-gradient(180deg, #f4f8ff -5.96%, #e3edff 62.21%);
  @media ${(props) => props.theme.mobile} {
    padding: 0px;
    background: none;
  }
`;
const SelectImage = styled.img`
  width: 13rem;
  height: 13rem;
  @media ${(props) => props.theme.mobile} {
    margin-bottom: 1rem;
  }
`;
const SelectContentContainer = styled(FlexWrap)`
  flex-direction: column;
  gap: 20px;
  padding: 32px 24px;
  @media ${(props) => props.theme.mobile} {
    width: 100%;
    background-color: #ffffff;
    border-radius: 20px;
    gap: 12px;
    box-shadow: 0px 0px 40px 0px #dde4ef;
  }
`;
const TaxfreeAdjustContent = styled.p<{ isTaxfree?: boolean }>`
  width: fit-content;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 150%;
  padding: 0.5rem 1.25rem;
  color: ${(props) => (props.isTaxfree ? '#F4FCFF' : '#666')};
  background-color: ${(props) => (props.isTaxfree ? '#1856CD' : '#e6e6e6')};
  border-radius: 6.25rem;
`;
const TaxfreeProductContent = styled(FlexWrap)`
  flex-direction: column;
`;
const ButtonWrapper = styled.button<{ isDisable?: boolean }>`
  padding: 16px 0;
  background-color: ${(props) => (props.isDisable ? '#5089F8' : '#246cf6')};
  border-radius: 12px;
  cursor: ${(props) => props.isDisable && ' not-allowed'};
`;
const PriceContainer = styled(FlexWrap)`
  position: relative;
`;
const CancleText = styled.del`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 150%;
  color: #b3b3b3;
`;
export default TaxfreeSmartSelect;
