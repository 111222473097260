import React from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router';

import ServiceJoinMobileWebp from 'assets/bg/mobile/m_serviceJoin.webp';
import ServiceJoinMobilePng from 'assets/bg/mobile/m_serviceJoin.png';
import BrowserTitle from 'components/_common/BrowserTitle';
import TopBanner from 'components/_common/TopBanner';
import MainContainerLayout from 'components/_common/MainContainerLayout';
import ServiceJoinPng from 'assets/bg/pc/serviceJoin.png';
import ServiceJoinWebp from 'assets/bg/pc/serviceJoin.webp';
import { checkWebpSupport } from 'utils/checkWebpSupport';
import { serviceJoinRoutes } from 'routes';
import TabButton from 'components/_common/TabButton';
import RefundAplication from 'components/_common/RefundAplication';

const links = [
  {
    text: '직접 신청',
    link: serviceJoinRoutes.serviceJoin,
    pathname: '/service-join',
  },
  {
    text: 'KTP 대리 신청',
    link: serviceJoinRoutes.ktp,
    pathname: '/service-join/ktp',
  },
];

function ServiceJoin() {
  return (
    <MainContainerLayout>
      <BrowserTitle
        title='면세판매장 직접신청'
        description='외국인관광객 면세판매장 지정신청'
        url='https://www.ktaxpay.com/service-join'
      />
      <TopBanner
        title='면세판매장 신청 가이드'
        content={`면세판매장 신청은 택스리펀 도입을 위한 선행 요건입니다.`}
        imgUrl={
          isMobile
            ? checkWebpSupport(ServiceJoinMobileWebp, ServiceJoinMobilePng)
            : checkWebpSupport(ServiceJoinWebp, ServiceJoinPng)
        }
      />
      <TabButton links={links} />
      <Outlet />
      <RefundAplication />
    </MainContainerLayout>
  );
}

export default ServiceJoin;
