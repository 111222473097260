import React from 'react';
import styled from 'styled-components';

import Typography from 'components/_common/Typography';

interface IProps {
  title: string;
  icon: React.ReactNode;
  description: string;
}

interface ServiceJoinStepProps {
  title: string;
  titleStrength: string;
  items: IProps[];
}

function ApplyStep({ title, titleStrength, items }: ServiceJoinStepProps) {
  return (
    <Container>
      <TitleWrapper>
        <Title>
          {title}
          <TitleStrength>{titleStrength}</TitleStrength>
        </Title>
      </TitleWrapper>
      <StepContainer>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <StepWrapper>
              <IconWrapper>
                <Icon src={String(item.icon)} alt={`${item.title} icon`} />
              </IconWrapper>
              <Typography
                size='1.125rem'
                fontWeight='700'
                lineHeight='1.6875rem'
                color='#246CF6'
              >
                {item.title}
              </Typography>
              <Typography size='1rem' fontWeight='700' lineHeight='1.5rem'>
                {item.description}
              </Typography>
            </StepWrapper>
            {index < items.length - 1 && <Bar />}
          </React.Fragment>
        ))}
      </StepContainer>
    </Container>
  );
}

export default ApplyStep;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 5.5rem 11.19rem 7.75rem;
  gap: 3.5rem;
  @media ${(props) => props.theme.tablet} {
    padding: 4.87rem 1.25rem 2.75rem;
    gap: 2.37rem;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 5rem 1rem 1.25rem;
    gap: 2.5rem;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px;
`;

const Title = styled.h2`
  font-weight: 700;
  @media ${(props) => props.theme.desktop} {
    font-size: 32px;
    line-height: 48px;
  }
  @media ${(props) => props.theme.tablet} {
    font-size: 24px;
    line-height: 36px;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 21px;
    line-height: 31.5px;
  }
`;

const TitleStrength = styled.span`
  color: #1856cd;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${(props) => props.theme.tablet} {
    flex-direction: column;
    width: 100%;
  }
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 15rem;
  height: 11.25rem;
  border-radius: 0.5rem;
  box-shadow: 0px 1px 8px 0px rgba(0, 6, 38, 0.16);

  @media ${(props) => props.theme.tablet} {
    width: 100%;
    height: 13.25rem;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background-color: #f4f8ff;
  border-radius: 50%;
  margin-bottom: 0.75rem;

  @media ${(props) => props.theme.tablet} {
    width: 5.5rem;
    height: 5.5rem;
  }
`;

const Icon = styled.img`
  width: 2.9rem;
  height: 2.9rem;

  @media ${(props) => props.theme.tablet} {
    width: 4rem;
    height: 4rem;
  }
`;

const Bar = styled.div`
  height: 1rem;
  background-color: #b3ccff;
  width: 1.75rem;

  @media ${(props) => props.theme.tablet} {
    height: 2.5rem;
    width: 1rem;
  }
`;
