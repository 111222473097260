import React from 'react';

import MainContainerLayout from 'components/_common/MainContainerLayout';
import BrowserTitle from 'components/_common/BrowserTitle';
import KTPProxyApplyFlow from 'components/ServiceJoin/KTPProxyApplyFlow';

function KTPProxyApply() {
  return (
    <MainContainerLayout>
      <BrowserTitle
        title='면세판매장 대리신청'
        description='외국인관광객 면세판매장 대리신청'
        url='https://www.ktaxpay.com/service-join/ktp'
      />
      <KTPProxyApplyFlow />
    </MainContainerLayout>
  );
}

export default KTPProxyApply;
