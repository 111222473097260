import React, { InputHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';
import { CalculateBox } from 'utils/common';
import ErrorAlert from 'assets/common/alert.png';
import { IComponentProps } from 'model/types';
import FlexWrap from './FlexWrap';

interface IStyleProps {
  margin: string;
  padding: string;
}

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

interface IProps
  extends InputHTMLAttributes<HTMLInputElement>,
    IComponentProps {
  error?: FieldError | undefined;
  label?: string;
  isRequired?: boolean;
  isActiveOutline?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, IProps & InputProps>(
  (
    {
      error,
      label,
      isRequired = false,
      margin = [0],
      padding = [0],
      isActiveOutline = true,
      ...inputAttributes
    }: IProps,
    ref,
  ): JSX.Element => {
    return (
      <Container margin={CalculateBox(margin)} padding={CalculateBox(padding)}>
        <InputWrapper>
          {Boolean(label) && (
            <LabelWrapper>
              <Label>{label}</Label>
              {isRequired && <RequiredDot>*</RequiredDot>}
            </LabelWrapper>
          )}
          <StyledInput
            ref={ref}
            isError={Boolean(error)}
            isActiveOutline={isActiveOutline}
            {...inputAttributes}
          />
        </InputWrapper>
        {error && (
          <ErrorWrapper>
            <ErrorIcon
              width='18px'
              height='18px'
              src={ErrorAlert}
              alt='에러.'
            />
            <Error>{error.message}</Error>
          </ErrorWrapper>
        )}
      </Container>
    );
  },
);

const Container = styled.div<IStyleProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
`;

const StyledInput = styled.input<{
  isError: boolean;
  isActiveOutline: boolean;
}>`
  display: block;
  width: 100%;
  border: 2px solid
    ${(props) =>
      props.isError && props.isActiveOutline ? '#f13e4b' : '#c4c4c4'};
  border-radius: 4px;
  padding: 12px 16px;
  font-size: 18px;
  line-height: 28px;
  outline-color: ${(props) => props.theme.primaryColor};
  @media ${(props) => props.theme.tablet} {
    padding: 14px 16px;
    font-size: 0.875rem;
    line-height: 22px;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 8px;
`;

const InputWrapper = styled(FlexWrap)`
  width: 100%;
  flex-direction: column;
  @media ${(props) => props.theme.desktop} {
    align-items: flex-start;
  }
`;

const Label = styled.label`
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  @media ${(props) => props.theme.tablet} {
    font-size: 1.3125rem;
    line-height: 24px;
  }
`;

const RequiredDot = styled.div`
  color: #f13e4b;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-left: 4px;
  padding-bottom: 6px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

const ErrorIcon = styled.img`
  margin-right: 8px;
`;

const Error = styled.p`
  color: #f13e4b;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
`;
Input.displayName = 'Input';

export default Input;
