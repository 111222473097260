import React from 'react';
import styled from 'styled-components';

import useDeviceCheck from 'hooks/useDeviceCheck';

import ArrowRightBlue from 'assets/common/arrow_right_blue_1856cd.png';

interface IImgProps {
  pcImg: React.ReactNode;
  tImg?: React.ReactNode;
  mImg?: React.ReactNode;
}

interface IProps extends IImgProps {
  bgColor: string;
  title?: string;
  titleBgColor?: string;
  titleStrength?: string;
  link?: string;
  linkDesc?: string;
  imgBackgroundColor?: string;
  subTitle: string;
  pcDescription?: string;
  tDescription?: string;
}

interface ServiceJoinStepProps {
  items: IProps[];
}

const ApplyImageStep: React.FC<ServiceJoinStepProps> = ({ items }) => {
  const { isTablet, isMobile } = useDeviceCheck();

  const deviceCheckImgChange = ({ pcImg, tImg, mImg }: IImgProps) => {
    if (isMobile && mImg) {
      return String(mImg);
    } else if (isTablet && tImg) {
      return String(tImg);
    } else {
      return String(pcImg);
    }
  };

  return (
    <>
      {items.map((item, idx) => (
        <Background key={`${item.title}_${idx}`} bgColor={item.bgColor}>
          <Container title={item.title}>
            <Wrapper>
              <TitleWrapper>
                {item.title && (
                  <Title titleBgColor={item.titleBgColor}>
                    <TitleStrength>{item.titleStrength}</TitleStrength>
                    {item.title}
                  </Title>
                )}
                <SubTitle>{item.subTitle}</SubTitle>
              </TitleWrapper>
              {item.link && (
                <LinkWrapper>
                  <Link href={item.link} target='_blank'>
                    {item.linkDesc}
                  </Link>
                  <ArrowRight src={ArrowRightBlue} alt='Arrow Right' />
                </LinkWrapper>
              )}
              {item.pcDescription && (
                <DescriptionWrapper>
                  <Description>
                    {isTablet ? item.tDescription : item.pcDescription}
                  </Description>
                </DescriptionWrapper>
              )}
              <ImageWrapper imgBackgroundColor={item.imgBackgroundColor}>
                <Image
                  src={deviceCheckImgChange({
                    pcImg: item.pcImg,
                    tImg: item.tImg,
                    mImg: item.mImg,
                  })}
                  alt={item.title}
                />
              </ImageWrapper>
              <Wave>
                <svg
                  data-name='Layer 1'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 1200 120'
                  preserveAspectRatio='none'
                >
                  <path
                    d='M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z'
                    fill={idx === 0 ? '#ffffff' : items[idx - 1].bgColor}
                  ></path>
                </svg>
              </Wave>
            </Wrapper>
          </Container>
        </Background>
      ))}
    </>
  );
};

export default ApplyImageStep;

// Styled Components
const Background = styled.div<{ bgColor: string }>`
  position: relative;
  background-color: ${(props) => props.bgColor};
`;

const Wave = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
`;

const Container = styled.div<{ title?: string }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1190px;
  width: 100%;
  margin: 0 auto;
  padding: 5.5rem 11.19rem 7.75rem;
  gap: 7.5rem;
  @media ${(props) => props.theme.tablet} {
    padding: 4.87rem 1.25rem 5.12rem;
    gap: 10rem;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 5rem 1rem 4.25rem;
    gap: 7.37rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${(props) => props.theme.tablet} {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.75rem;
`;

const Title = styled.h2<{ titleBgColor?: string }>`
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.6875rem;
  color: #ffffff;
  background-color: ${(props) => props.titleBgColor || '#ffffff'};
  border-radius: 6.25rem;
`;

const TitleStrength = styled.span`
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.6875rem;
`;

const SubTitle = styled.h3`
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.625rem;

  @media ${(props) => props.theme.mobile} {
    font-size: 1.3125rem;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  line-height: 1.96875rem;
  margin-top: 0.75rem;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.3125rem;
  font-weight: 700;
  color: #1856cd;
`;

const ArrowRight = styled.img`
  width: 1.25rem;
  height: 1.25rem;
`;
const DescriptionWrapper = styled.div``;

const Description = styled.p`
  margin-top: 0.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.6875rem;
  text-align: center;
  white-space: pre-wrap;
`;

const ImageWrapper = styled.div<{ imgBackgroundColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.imgBackgroundColor};
  border-radius: 0.75rem;
  margin-top: 2.5rem;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  border-radius: 0.75rem;
`;
