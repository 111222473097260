import { useLazyImageObserver } from 'hooks/useLazyImageObserver';
import { memo } from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  src: string;
  width?: string;
  height?: string;
  alt: string;
  objectFit?: string;
  borderRadius?: string;
  borderRadiusLeftRight?: string;
}
const LazyImage = memo(
  ({
    src,
    width = '100%',
    height = '100%',
    alt,
    objectFit,
    borderRadius,
    borderRadiusLeftRight,
  }: IProps) => {
    const { imageSrc, imageRef } = useLazyImageObserver({ src });

    return (
      <Image
        ref={imageRef}
        src={imageSrc}
        width={width}
        height={height}
        alt={alt}
        objectFit={objectFit}
        borderRadius={borderRadius}
        borderRadiusLeftRight={borderRadiusLeftRight}
      />
    );
  },
);
const Image = styled.img<{
  objectFit?: string;
  borderRadius?: string;
  borderRadiusLeftRight?: string;
  width?: string;
  height?: string;
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  object-fit: ${(props) => props.objectFit && props.objectFit};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  ${(props) =>
    props.borderRadiusLeftRight &&
    css`
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    `}
`;

export default LazyImage;
