import FlexWrap from 'components/_common/FlexWrap';
import { TaxRefundContent } from 'constants/TaxRefund';
import React from 'react';
import styled from 'styled-components';
import MedicalGuideItem from './MedicalGuideItem';

function MedicalTaxRefundGuide() {
  const {
    processSection: { medical: processMedical },
  } = TaxRefundContent;
  return (
    <Wrapper>
      <Container>
        <Title>
          <ColoredTitle>KTP</ColoredTitle>
          택스리펀 서비스 도입 방법은?
        </Title>
        <List>
          {processMedical.map((item, idx) => (
            <MedicalGuideItem
              key={idx}
              item={item}
              idx={idx}
              length={processMedical.length}
            />
          ))}
        </List>
      </Container>
    </Wrapper>
  );
}

export default MedicalTaxRefundGuide;

const Wrapper = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
`;

const Container = styled.div`
  max-width: 1190px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6.75rem 7.75rem 6.25rem;
  @media ${(props) => props.theme.tablet} {
    padding: 3.35rem 1.25rem 3.35rem;
  }
`;

const Title = styled.h2`
  font-size: 3rem;
  line-height: 150%;
  text-align: center;
  font-weight: 700;
  margin-bottom: 16px;
  word-break: keep-all;
  @media ${(props) => props.theme.tablet} {
    font-size: 2.5rem;
    margin-top: 2rem;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 2rem;
  }
`;

const ColoredTitle = styled(Title)`
  color: #246cf6;
  display: inline;
  margin-right: 0.5rem;
`;

const List = styled(FlexWrap)`
  flex-direction: column;
  width: 100%;
`;
