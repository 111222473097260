import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { isMobile } from 'react-device-detect';
import AWS from 'aws-sdk';

import { applyFranchisee } from 'api';
import BrowserTitle from 'components/_common/BrowserTitle';
import LoadingView from 'components/_common/LoadingView';
import MainContainerLayout from 'components/_common/MainContainerLayout';
import TopBanner from 'components/_common/TopBanner';
import InnerLayout from 'components/_common/InnerLayout';
import FormSection from 'components/Application/FormSection';
import InquiryResult from 'components/Application/InquiryResult';
import ApplicationBanner from 'assets/bg/pc/homeBanner.png';
import mApplicationBannerWebp from 'assets/bg/mobile/m_homeBanner.webp';
import mApplicationBanner from 'assets/bg/mobile/m_homeBanner.png';
import { IApplicationFormValues, IError } from 'model/types';
import { checkWebpSupport } from 'utils/checkWebpSupport';
import { getNumberTime } from 'utils/format';

function Application() {
  const [isTermSelected, setIsTermSelected] = useState(false);
  const [isApplied, setIsApplied] = useState(false);

  const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
  const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
  const REGION = process.env.REACT_APP_REGION;
  const S3_BUCKET = process.env.REACT_APP_S3_BUCKET!;

  AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const uploadContact = (file: IApplicationFormValues) => {
    const date = getNumberTime(new Date());
    const params = {
      ACL: 'public-read',
      Body: JSON.stringify(file),
      Bucket: S3_BUCKET,
      Key: `upload/${date}/` + file.email,
    };
    myBucket
      .putObject(params)
      .on('httpUploadProgress', (evt) => {})
      .send((err) => {
        if (err) console.log(err);
        setIsTermSelected(false);
        setIsApplied(true);
        alert('도입문의 신청이 완료되었습니다.');
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<IApplicationFormValues>({
    defaultValues: {
      name: '',
      phoneNumber: '',
      email: '',
      franchiseeName: '',
      inquiry: '',
    },
  });
  const mutation = useMutation<null, IError, IApplicationFormValues>(
    (payload) => applyFranchisee(payload),
    {
      onError: (error) => {
        console.log(error);
        alert('가맹점 신청에 실패했습니다.\n디시 한번 시도해주세요.');
      },
      onSuccess: () => {
        setIsTermSelected(false);
        setIsApplied(true);
        window.scrollTo(0, 0);
      },
    },
  );

  const onToggleTerm = useCallback(() => {
    setIsTermSelected((prevState) => !prevState);
  }, []);

  const onSubmit: SubmitHandler<IApplicationFormValues> = (data, event) => {
    event?.preventDefault();
    mutation.mutate(data);
    // uploadContact(data);
  };

  return (
    <MainContainerLayout>
      {mutation.isLoading && <LoadingView />}
      <BrowserTitle
        title='택스리펀드 서비스 도입 및 가맹 문의 | KTP'
        description='KTP는 사후면세(TAX FREE) 신청에 필요한 외국인 관광객 면세판매장 지정 신청서 등 복잡한 서류 업무를 대행으로 신청해드립니다.'
        url='https://www.ktaxpay.com/application'
      />
      <TopBanner
        title='서비스 도입 문의'
        content='KTP 서비스 도입, 사업 제휴 등 모든 문의를 환영합니다.'
        imgUrl={
          isMobile
            ? checkWebpSupport(mApplicationBannerWebp, mApplicationBanner)
            : ApplicationBanner
        }
      />
      <Container>
        {isApplied ? (
          <InquiryResult />
        ) : (
          <>
            <Title>궁금하신 내용을 남겨주세요!</Title>
            <FormSection
              register={register}
              handleSubmit={handleSubmit}
              errors={errors}
              onSubmit={onSubmit}
              isTermSelected={isTermSelected}
              onToggleTerm={onToggleTerm}
              getValues={getValues}
            />
          </>
        )}
      </Container>
    </MainContainerLayout>
  );
}

const Container = styled(InnerLayout)`
  padding: 160px 0;
  @media ${(props) => props.theme.mobile} {
    padding: 100px 16px;
  }
`;

const Title = styled.h1`
  font-size: 40px;
  line-height: 56px;
  font-weight: 700;
  color: ${(props) => props.theme.primaryTextColor};
  text-align: center;
  margin-bottom: 80px;
  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;
  }
`;
export default Application;
