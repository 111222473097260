import BrowserTitle from 'components/_common/BrowserTitle';
import MainContainerLayout from 'components/_common/MainContainerLayout';
import RefundAplication from 'components/_common/RefundAplication';
import TaxfreeSmartSelect from 'components/Home/TaxfreeSmartSelect';
import SalesIncrease from 'components/Home/SalesIncrease';
import TaxFreeBenefitsSection from 'components/Home/TaxFreeBenefitsSection';
import KTPExample from 'components/Home/KTPExample';
import ReviewSection from 'components/Home/ReviewSection';
import KTPService from 'components/Home/KTPService';
import VariableBrand from 'components/Home/VariableBrand';
import VideoSection from 'components/Home/VideoSection';
import VideoBanner from 'components/Medical/VideoBanner';

function Store() {
  return (
    <MainContainerLayout>
      <BrowserTitle
        title='KTP 케이티피 한국 택스리펀'
        description='스마트 텍스프리 서비스 KTP(케이티피), 이용료 없이 지금 바로 사용해보세요.'
        url='https://www.ktaxpay.com/'
      />
      <VideoBanner />
      <TaxfreeSmartSelect />
      <SalesIncrease />
      <VideoSection />
      <KTPExample />
      <TaxFreeBenefitsSection />
      <KTPService />
      <VariableBrand />
      <ReviewSection />
      <RefundAplication />
    </MainContainerLayout>
  );
}

export default Store;
