import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import phoneCallIcon from 'assets/home/common/phoneCall.png';
import Icon from 'components/_common/Icon';

function InquiryFloatingButton() {
  const [isDisplay, setIsDisplay] = useState(false);
  const homeBannerHeight = useRef<number | undefined>(0);

  const displayInquiry = () => {
    if (!homeBannerHeight.current) return;
    const currsetScrollY = window.scrollY;
    if (homeBannerHeight.current < currsetScrollY + 150) {
      setIsDisplay(true);
    } else {
      setIsDisplay(false);
    }
  };

  useEffect(() => {
    homeBannerHeight.current =
      document.getElementById('home-banner')?.offsetHeight;
    window.addEventListener('scroll', displayInquiry);
    return () => {
      window.removeEventListener('scroll', displayInquiry);
    };
  }, []);
  return (
    <Container isDisplay={isDisplay}>
      <CallButton href='tel: 02-6275-8011'>
        <Icon imgUrl={phoneCallIcon} width='28px' height='28px' />
        전화문의
      </CallButton>
    </Container>
  );
}

const Container = styled.div<{ isDisplay: boolean }>`
  display: flex;
  align-items: flex-end;
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 100vw;
  padding: 0 14px;
  transition: all 300ms ease-in;
  opacity: ${(props) => (props.isDisplay ? 1 : 0)};
  z-index: 10;
`;
const CallButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
  background-color: #246cf6;
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  padding: 14px 0;
  border-radius: 8px;
`;
export default InquiryFloatingButton;
