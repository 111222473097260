import React from 'react';
import { Link } from 'react-router-dom';
import { refundRoutes, serviceJoinRoutes } from 'routes';

import styled from 'styled-components';

const subLinks = {
  '/refund': [
    {
      text: '매장 환급',
      link: refundRoutes.taxRefund,
      pathname: '/refund',
    },
    {
      text: '병원 환급',
      link: refundRoutes.medical,
      pathname: '/refund/medical',
    },
    {
      text: '해외 간편 결제',
      link: refundRoutes.globalPayment,
      pathname: '/refund/global-payment',
    },
  ],
  '/service-join': [
    {
      text: '직접 신청',
      link: serviceJoinRoutes.serviceJoin,
      pathname: '/service-join',
    },
    {
      text: 'KTP 대리 신청',
      link: serviceJoinRoutes.ktp,
      pathname: '/service-join/ktp',
    },
  ],
};
function SubMenu({
  itemPathname,
  checkVideo,
  isVideoBannerPass,
}: {
  itemPathname: string;
  checkVideo: boolean;
  isVideoBannerPass: boolean;
}) {
  return (
    <Container className='subMenu'>
      {itemPathname === '/refund' &&
        subLinks['/refund'].map((link, idx) => (
          <MenuLink key={idx} to={link.link}>
            <Menu checkVideo={checkVideo} isVideoBannerPass={isVideoBannerPass}>
              {link.text}
            </Menu>
          </MenuLink>
        ))}
      {itemPathname === '/service-join' &&
        subLinks['/service-join'].map((link, idx) => (
          <MenuLink key={idx} to={link.link}>
            <Menu checkVideo={checkVideo} isVideoBannerPass={isVideoBannerPass}>
              {link.text}
            </Menu>
          </MenuLink>
        ))}
      {<SubMenuBg className='menu_bg' checkVideo={checkVideo} />}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 60px;
  position: absolute;
  top: 50px;
  left: 0px;
  width: 500px;
  height: 60px;
  pointer-events: none;
  transition: height 300ms ease-in;
  padding-top: 20px;
`;

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  transition: height 300ms ease-in;
  overflow: hidden;
  height: 0px;
`;

const Menu = styled.div<{ checkVideo: boolean; isVideoBannerPass: boolean }>`
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  cursor: pointer;
  color: #191919;
  color: ${(props) =>
    props.checkVideo && !props.isVideoBannerPass ? '#fff' : '#191919'};
  :hover {
    opacity: 0.6;
  }
`;
const SubMenuBg = styled.div<{ checkVideo: boolean }>`
  position: fixed;
  height: 0px;
  z-index: -1;
  top: 70px;
  left: 0px;
  background-color: ${(prosp) => (prosp.checkVideo ? 'none' : '#fff')};

  width: 100vw;
  transition: height 300ms ease-in;
`;
export default SubMenu;
