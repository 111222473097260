import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import FlexWrap from 'components/_common/FlexWrap';
import InnerLayout from 'components/_common/InnerLayout';
import styled from 'styled-components';

import activeCheckImg from 'assets/common/active_check.png';
import emptyCheckImg from 'assets/common/empty_check.png';
import backgroundImg from 'assets/refund/aplicationBg.png';
import { SubmitHandler, useForm } from 'react-hook-form';
import FormSection from 'components/Application/FormSection';
import { IApplicationFormValues, IError } from 'model/types';
import { useMutation } from 'react-query';
import { applyFranchisee } from 'api';
import RefundModal from 'components/Refund/RefundModal';
import LoadingView from './LoadingView';
import useDeviceCheck from 'hooks/useDeviceCheck';
import { useLocation, useNavigate } from 'react-router';
import { routes } from 'routes';

interface IForm {
  name: string;
  phoneNumber: string;
  email: string;
  franchiseeName: string;
  inquiry: string;
}

function RefundAplication() {
  const { isTablet } = useDeviceCheck();
  const [isTermSelected, setIsTermSelected] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const yPosition = location?.state?.yPosition || 0;
  useEffect(() => {
    if (yPosition > 0) {
      window.scrollTo(0, yPosition);
    }
  }, [yPosition]);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      name: '',
      phoneNumber: '',
      email: '',
      franchiseeName: '',
      inquiry: '',
    },
  });
  const { email, franchiseeName, inquiry, name, phoneNumber } = watch();
  useEffect(() => {
    if (franchiseeName && name && email && phoneNumber && inquiry && isCheck) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [watch()]);

  const { isLoading, mutate } = useMutation<null, any, IApplicationFormValues>(
    (payload) => applyFranchisee(payload),
    {
      onError: (error) => {
        let message =
          '가맹점 신청에 실패했습니다.\n잠시 후 다시 시도해주세요.\n(유선 문의: 02-6275-8011)';
        if (error.response.data.code === 'P0001') {
          message = error.response.data.message;
        }
        alert(message);
      },
      onSuccess: () => {
        setIsTermSelected(false);
        setIsCheck(false);
        reset();
        const scrollY = window.scrollY || window.pageYOffset;
        navigate(routes.applicationComplete, {
          state: {
            yPosition: scrollY,
          },
        });
      },
      retry: 1,
    },
  );

  const onSubmit: SubmitHandler<IApplicationFormValues> = (data, event) => {
    event?.preventDefault();
    const newData = {
      ...data,
      email: data.email.toLowerCase(),
    };

    if (isActive) {
      mutate(newData);
    } else if (!data.inquiry) {
      alert('문의내용을 입력 해주세요.');
    } else if (data.inquiry.length > 1000) {
      alert('문의내용은 1000자를 넘길 수 없습니다.');
    } else {
      alert('개인정보 수집에 동의 및 필수 정보를 입력 해주세요.');
    }
  };

  const onToggleTerm = useCallback(() => {
    setIsTermSelected((prevState) => !prevState);
  }, []);

  return (
    <Wrapper id='inquiry'>
      {isLoading && <LoadingView />}
      <Inner>
        <Container>
          <FlexWrap dir='column' alignItems='center'>
            <Title>도입 문의</Title>
            <Content>
              KTP 서비스 도입에 {isTablet && `\n`}궁금하신 내용을 남겨주세요.
            </Content>
          </FlexWrap>
          <FormSection
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            onSubmit={onSubmit}
            isTermSelected={isTermSelected}
            onToggleTerm={onToggleTerm}
            getValues={getValues}
            taxRefund
          />

          {isTablet && (
            <FlexWrap
              dir='column'
              alignItems='center'
              gap='20px'
              margin='50px 0 0 0 '
            >
              <CheckBox>
                <CheckImg
                  src={isCheck ? activeCheckImg : emptyCheckImg}
                  onClick={() => setIsCheck((prev) => !prev)}
                  alt='체크박스.'
                />
                <CheckContent>
                  <Line onClick={() => setIsModal(true)}>
                    개인정보 수집 및 이용안내
                  </Line>
                  에 동의합니다.
                </CheckContent>
              </CheckBox>
              <ButtonWrapper>
                <Button onClick={handleSubmit(onSubmit)} isActive={isActive}>
                  문의하기
                </Button>
              </ButtonWrapper>
            </FlexWrap>
          )}
          {isModal && <RefundModal setIsModal={setIsModal} />}
          {!isTablet && (
            <FlexWrap
              dir='column'
              gap='20px'
              justifyContent='center'
              margin='3rem 0 0 0'
            >
              <CheckBox>
                <CheckImg
                  width='24px'
                  height='24px'
                  src={isCheck ? activeCheckImg : emptyCheckImg}
                  onClick={() => setIsCheck((prev) => !prev)}
                  alt='체크박스.'
                />
                <CheckContent>
                  <Line onClick={() => setIsModal(true)}>
                    개인정보 수집 및 이용안내
                  </Line>
                  에 동의합니다.
                </CheckContent>
              </CheckBox>
              <ButtonWrapper>
                <Button onClick={handleSubmit(onSubmit)} isActive={isActive}>
                  문의하기
                </Button>
              </ButtonWrapper>
            </FlexWrap>
          )}
        </Container>{' '}
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background-color: #05191f;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${backgroundImg});
  background-size: cover;
  text-align: center;
  padding: 6.12rem 0;
  @media ${(props) => props.theme.desktop} {
    min-height: 56.5625rem;
  }
  @media ${(props) => props.theme.tablet} {
    padding: 5.94rem 0;
  }
`;
const Inner = styled(InnerLayout)`
  padding: 0px 20px;
`;

const Container = styled(FlexWrap)`
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
`;
const Title = styled.p`
  font-size: 2rem;
  font-weight: 700;
  line-height: 150%;
  color: #ffffff;
  @media ${(props) => props.theme.tablet} {
    font-size: 1.75rem;
  }
`;

const Content = styled.p`
  font-size: 1.5rem;
  line-height: 150%;
  color: #ffffff;
  white-space: pre-line;
  @media ${(props) => props.theme.tablet} {
    margin-top: 0.75rem;
    font-size: 1.125rem;
  }
`;

const CheckBox = styled.div`
  display: flex;
  gap: 10px;
`;
const CheckImg = styled.img`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  @media ${(props) => props.theme.tablet} {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
const CheckContent = styled.div`
  font-size: 1.125rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #ffffff;
  @media ${(props) => props.theme.tablet} {
    font-size: 1rem;
  }
`;
const Line = styled.span`
  position: relative;
  cursor: pointer;
  ::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #fff;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const Button = styled.button<{ isActive: boolean }>`
  font-size: 1.75rem;
  line-height: 150%;
  font-weight: 700;
  width: 16.25rem;
  height: 4rem;
  border-radius: 0.4rem;
  color: #ffffff;
  background-color: ${(props) => (props.isActive ? '#1856cd' : '#808080')};
  @media ${(props) => props.theme.tablet} {
    font-size: 1.125rem;
    width: 12.1875rem;
    height: 3.25rem;
  }
`;

export default RefundAplication;
