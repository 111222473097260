import Policy1Detail1Webp from 'assets/service/policy/policy1/policyDetail1.webp';
import Policy1Detail1 from 'assets/service/policy/policy1/policyDetail1.png';
import Policy1Detail2Webp from 'assets/service/policy/policy1/policyDetail2.webp';
import Policy1Detail2 from 'assets/service/policy/policy1/policyDetail2.png';
import Policy1Detail3Webp from 'assets/service/policy/policy1/policyDetail3.webp';
import Policy1Detail3 from 'assets/service/policy/policy1/policyDetail3.png';
import Policy1Detail4Webp from 'assets/service/policy/policy1/policyDetail4.webp';
import Policy1Detail4 from 'assets/service/policy/policy1/policyDetail4.png';
import Policy1Detail5Webp from 'assets/service/policy/policy1/policyDetail5.webp';
import Policy1Detail5 from 'assets/service/policy/policy1/policyDetail5.png';

import Policy2Detail1Webp from 'assets/service/policy/policy2/policyDetail1.webp';
import Policy2Detail1 from 'assets/service/policy/policy2/policyDetail1.png';
import Policy2Detail2Webp from 'assets/service/policy/policy2/policyDetail2.webp';
import Policy2Detail2 from 'assets/service/policy/policy2/policyDetail2.png';
import Policy2Detail3Webp from 'assets/service/policy/policy2/policyDetail3.webp';
import Policy2Detail3 from 'assets/service/policy/policy2/policyDetail3.png';
import Policy2Detail4Webp from 'assets/service/policy/policy2/policyDetail4.webp';
import Policy2Detail4 from 'assets/service/policy/policy2/policyDetail4.png';
import Policy2Detail5Webp from 'assets/service/policy/policy2/policyDetail5.webp';
import Policy2Detail5 from 'assets/service/policy/policy2/policyDetail5.png';

import Policy3Detail1Webp from 'assets/service/policy/policy3/policyDetail1.webp';
import Policy3Detail1 from 'assets/service/policy/policy3/policyDetail1.png';
import Policy3Detail2Webp from 'assets/service/policy/policy3/policyDetail2.webp';
import Policy3Detail2 from 'assets/service/policy/policy3/policyDetail2.png';
import Policy3Detail3Webp from 'assets/service/policy/policy3/policyDetail3.webp';
import Policy3Detail3 from 'assets/service/policy/policy3/policyDetail3.png';
import Policy3Detail4Webp from 'assets/service/policy/policy3/policyDetail4.webp';
import Policy3Detail4 from 'assets/service/policy/policy3/policyDetail4.png';
import Policy3Detail5Webp from 'assets/service/policy/policy3/policyDetail5.webp';
import Policy3Detail5 from 'assets/service/policy/policy3/policyDetail5.png';
import Policy3Detail6Webp from 'assets/service/policy/policy3/policyDetail6.webp';
import Policy3Detail6 from 'assets/service/policy/policy3/policyDetail6.png';

import Policy4Detail1Webp from 'assets/service/policy/policy4/policyDetail1.webp';
import Policy4Detail1 from 'assets/service/policy/policy4/policyDetail1.png';
import Policy4Detail2Webp from 'assets/service/policy/policy4/policyDetail2.webp';
import Policy4Detail2 from 'assets/service/policy/policy4/policyDetail2.png';
import Policy4Detail3Webp from 'assets/service/policy/policy4/policyDetail3.webp';
import Policy4Detail3 from 'assets/service/policy/policy4/policyDetail3.png';
import Policy4Detail4Webp from 'assets/service/policy/policy4/policyDetail4.webp';
import Policy4Detail4 from 'assets/service/policy/policy4/policyDetail4.png';
import Policy4Detail5Webp from 'assets/service/policy/policy4/policyDetail5.webp';
import Policy4Detail5 from 'assets/service/policy/policy4/policyDetail5.png';
import Policy4Detail6Webp from 'assets/service/policy/policy4/policyDetail6.webp';
import Policy4Detail6 from 'assets/service/policy/policy4/policyDetail6.png';
import Policy4Detail7Webp from 'assets/service/policy/policy4/policyDetail7.webp';
import Policy4Detail7 from 'assets/service/policy/policy4/policyDetail7.png';

import Policy5Detail1Webp from 'assets/service/policy/policy5/policyDetail1.webp';
import Policy5Detail1 from 'assets/service/policy/policy5/policyDetail1.png';
import Policy5Detail2Webp from 'assets/service/policy/policy5/policyDetail2.webp';
import Policy5Detail2 from 'assets/service/policy/policy5/policyDetail2.png';
import Policy5Detail3Webp from 'assets/service/policy/policy5/policyDetail3.webp';
import Policy5Detail3 from 'assets/service/policy/policy5/policyDetail3.png';
import Policy5Detail4Webp from 'assets/service/policy/policy5/policyDetail4.webp';
import Policy5Detail4 from 'assets/service/policy/policy5/policyDetail4.png';
import Policy5Detail5Webp from 'assets/service/policy/policy5/policyDetail5.webp';
import Policy5Detail5 from 'assets/service/policy/policy5/policyDetail5.png';
import Policy5Detail6Webp from 'assets/service/policy/policy5/policyDetail6.webp';
import Policy5Detail6 from 'assets/service/policy/policy5/policyDetail6.png';

import styled from 'styled-components';
import { checkWebpSupport } from 'utils/checkWebpSupport';
import { isMobile } from 'react-device-detect';

const Content = styled.span`
  font-size: 18px;
  line-height: 28px;
  text-align: left;
  white-space: pre-line;
  font-weight: 400;
  a {
    text-decoration: underline;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 16px;
    line-height: 28px;
  }
`;

const Bold = styled.span`
  font-weight: 700;
`;

const Tab = styled.span`
  margin-left: 9px;
`;

export const PolicyItemContent = [
  {
    id: 1,
    title: '자영업자 고용보험료 지원사업',
    subTitle: '매출을 올리는 KTP의 이야기를 담았습니다.',
    createDate: '2023.02.01',
    image: checkWebpSupport(Policy1Detail1Webp, Policy1Detail1),
    alt: '정책 프로필.',
    keyword: '자영업자 고용보험료 지원사업',
    isPolicy: true,
    content: [
      <Content>
        안녕하세요 😀 <br />
        <br />
        매출을 올리는 똑똑한 변화, <br />
        모바일 택스리펀드 서비스 KTP 입니다.
        <br />
        <br /> KTP는 오늘도 사장님들의 매출 향상을 위해 더 간편하고 빠른
        택스리펀드 서비스를 만들어 가고 있는데요!
        <br />
        <br /> 사장님들과 함께 성장하는 서비스이니만큼, 택스리펀드와 더불어
        도움이 될 다양한 소식을 전해 드리고자 2023년부터 서비스 블로그를
        운영하게 되었습니다 🎉
        <br />
        <br /> 첫 번째로 소개해드릴 정보는 요즘 아는 사장님들은 전부 신청한다는
        <Bold> 고용보험료 지원사업</Bold>입니다!
      </Content>,
      <Content>
        다소 생소하게 느껴지실 수도 있는 고용보험! <br /> <br />
        먼저, 고용보험이란 실직 상태인 근로자가 실업급여를 받을 수 있게 하는
        <Bold>실업보험사업</Bold>과 적극적인 취업 알선을 위한
        <Bold>고용안정사업,</Bold>
        마지막으로 근로자의 <Bold>직업능력개발사업</Bold> 총 3가지 사업으로
        구성된 근로자를 위한 사회보험제도입니다. 1991년 국민들의 높은 실업률로
        인해 실업보험제도의 필요성이 먼저 제기가 되었고, 90년대 후반 국가
        차원에서 고용보험제도를 도입하게 되었습니다. 현재, 노동시장의 건강과
        국민 생활 안정을 위해 다방면으로 근로자들을 지원하는 사업을 운영하고
        있다는 점!
      </Content>,
      <Content>
        네, 가능합니다 : ) <br />
        <br />
        아마, 고용보험제도의 가입 대상은 근로자이기 때문에 소상공인 및 자영업자
        사장님들은 가입이 안된다고 알고 계실텐데요! 고용노동부는
        자영업자·소상공인의 폐업 후, 고용안정과 직업능력개발 지원을 위해 자격을
        갖춘 소상공인 및 자영업자 사장님들도 가입이 가능하도록 제도를 운영하고
        있습니다. <Bold>가입 자격 대상</Bold>은 아래와 같습니다.
        <br />
        <br /> 가입 신청일 현재, 실제 사업을 영위
        <br /> 고용 중인 근로자가 50명 미만인 사업자의 사업주 <br />
        <br />위 자격을 갖춘 소상공인 및 자영업자 사장님들이라면 누구나!
        고용보험 가입 대상이 된다는 점, <Bold>꼭 기억해주세요.</Bold>
      </Content>,
      <Content>
        아직도 많은 소상공인 및 자영업자 사장님들은 고용보험에 대해 잘 모르고
        계시는데요.
        <br />
        <br />
        이러한 문제점을 해결하고자 소상공인 진흥공단에서는
        <Bold>자영업자 고용보험료 지원 사업</Bold>을 통해, 사회의 제도적
        안전망인 고용보험제도에 많은 소상공인 및 자영업자 사장님들의 가입을
        지원하고 있어요. 가입 후, 납부할 고용보험료를
        <Bold>최대 5년 간 50%까지 지원</Bold>해주는 큰 혜택의 사업으로 이번 지원
        사업을 통해 가입하시게 된다면 큰 부담 없이 미래를 대비할 수 있는 좋은
        기회가 될 것 같습니다.
        <br />
        <br /> 지원 절차는 아래 순서를 참고해주세요!
        <br />
        <br />① 자영업자 고용보험 가입 및 납부
        <br />② 자영업자 고용보험료 지원사업 신청
        <br />③ 지원사업 대상자 확인
        <br />④ 자영업자 고용보험로 납부확인
        <br />⑤ 자영업자 고용보험료 환급 지원
      </Content>,
      <Content>
        고용보험 제도 이외에도 소상공인 및 자영업자 사장님이라면 누구나 신청하고
        혜택을 받을 수 있는 제도가 하나 더 있습니다!
        <br />
        <br /> 바로, 강력한 외국인 고객 유치효과로 매출을 효과적으로 향상시킬 수
        있는 <Bold>택스리펀드(사후면세제도)</Bold>인데요.
        <br />
        <br /> 택스리펀드는
        <Bold>일반 판매 사업장도 공항의 면세점과 같이 면세 판매 자격</Bold>을
        얻을 수 있게 하는 제도로, 외국인 관광객 고객은 면세 가격으로 구매할 수
        있는 택스리펀 매장을 더 선호하는데요. 관광객은 부가세가 제외된 면세
        가격으로 구매할 수 있어 혜택을 받고, 사업주 분들은 별도의 비용 투자 없이
        할인 프로모션을 제공해 강력한 마케팅 효과를 경험하실 수 있습니다.
        <br />
        <br />
        지원 정책을 알뜰히 챙기시는 똑똑한 사장님들이시라면, 택스리펀드도 꼭 한
        번 살펴보세요 : )<br /> (아래 배너를 클릭하시면 한눈에 읽어보실 수
        있습니다!)
      </Content>,
    ],
    detail: {
      type: 1,
      title: '자영업자 고용보험료 지원사업',
      subTitle: '매출을 올리는 KTP의 이야기를 담았습니다.',
      bannerBg: checkWebpSupport(Policy1Detail2Webp, Policy1Detail2),
      contentTitle: [
        '',
        '🔍 고용보험제도가 뭔가요?',
        '🔍 자영업자나 소상공인도 가입이 가능한가요?',
        '🔍 고용보험료를 지원해주는 지원사업!',
        '🔍 도움이 되는 또 하나의 제도, 택스리펀드',
      ],
      image: [
        checkWebpSupport(Policy1Detail1Webp, Policy1Detail1),
        checkWebpSupport(Policy1Detail2Webp, Policy1Detail2),
        checkWebpSupport(Policy1Detail3Webp, Policy1Detail3),
        checkWebpSupport(Policy1Detail4Webp, Policy1Detail4),
        checkWebpSupport(Policy1Detail5Webp, Policy1Detail5),
      ],
    },
  },
  {
    id: 2,
    title: '택스리펀드 매장 가입',
    subTitle: '매출을 올리는 KTP의 이야기를 담았습니다.',
    createDate: '2023.02.06',
    image: checkWebpSupport(Policy2Detail1Webp, Policy2Detail1),
    alt: '정책 프로필.',
    keyword: '택스리펀드 매장 가입',
    isPolicy: true,
    content: [
      <Content>
        안녕하세요 😀 <br />
        <br />
        매출을 올리는 똑똑한 변화, <br />
        모바일 택스리펀드 서비스 KTP 입니다.
        <br />
        <br />
        지난번, 소상공인분들에게 꼭 도움이 될 만한 <Bold>고용보험료</Bold>
        지원사업 내용을 소개해드렸는데요!
        <br />
        <br />
        오늘 소개해드릴 내용은 외국인 관광객이 점점 늘어나는 요즘, 매출을
        올리는데 큰 효과가 있는 <Bold>외국인 관광객 부가세 환급 제도,</Bold>
        바로 <Bold>택스리펀드</Bold>에 대한 내용입니다!
      </Content>,
      <Content>
        일반 과세자라면 누구나!
        <br />
        비용 투자 없이 매출을 효과적으로 올릴 수 있는 제도!
        <br />
        <br />
        택스리펀드(=텍스프리)는 국내에서 사후면세제도라는 이름으로 운영되고 있는
        제도로, 전 세계 60여 개 국가에서 외국인 관광객 유치와 소상공인 경제
        활성화를 위해 활용하고 있는 제도입니다. 외국인 관광객이 여행 중에 물건을
        구입한 뒤, 3개월 이내에 출국을 하게 되면 이를 수출로 간주하여 상품 결제
        시 포함된 부가세(VAT)를 다시 환급해주는 제도인데요. 외국인 관광객은
        부가세(VAT)만큼 할인된 가격으로 구매를 할 수 있어 택스리펀드 매장을 더
        선호하게 되고, 사업주는 택스리펀드 매장으로 홍보를 할 수 있어 외국인
        관광객 모객을 통한 매출 향상에 큰 도움이 되는 제도입니다.
      </Content>,
      <Content>
        매장에 택스리펀드를 도입하시려면 가장 먼저 ‘외국인 관광객 면세판매장
        지정신청’이 필요한데요. ‘외국인 관광객 면세판매장 지정신청’은
        사업자등록과 같이, 외국인 관광객에게 면세 판매를 하는 자격을 취득하는
        민원 신청을 말합니다. 직접 관할 세무서 방문을 통해 신청하실 수도 있지만,
        홈택스를 통해 보다 간편히 신청하실 수 있어요!
        <br />
        <br />
        <a href='https://url.kr/ax64l9'>🔗 홈택스 신청 방법 확인하기(클릭)</a>
        <br />
        <br />
        ‘외국인 관광객 면세판매장 지정신청’이 완료 후, 수령하신 지정신청증을 KTP
        앱에 첨부해주시면 승인 후, 바로 택스리펀드 기능을 활용하실 수 있습니다!
        🙂
        <br />
        <br />
        특히, KTP는 모바일 앱 기반 택스리펀드 서비스로{' '}
        <Bold>도입 및 사용 방법이 간편</Bold>하고 무엇보다 이용료가{' '}
        <Bold>평.생.무.료.</Bold>인 점이 가장 큰 장점이에요👍
      </Content>,
      <Content>
        사업주의 입장에서는 택스리펀드 도입 후, 매출을 향상시킬 수 있는 효과가
        가장 크다고 할 수 있어요!
        <br />
        <br />
        택스리펀드는 면세로 판매할 수 있는 자격을 취득하는 것으로 택스리펀드
        처리가 된 판매 건은 부가세 납부를 하지 않아, 별도의 비용 손실 없이
        고객에게 부가세만큼 할인된 가격으로 혜택을 제공할 수 있습니다. 외국인
        관광객은 더 저렴하게 상품을 구매할 수 있는 택스리펀드 매장을 더
        선호하여, 더 많은 모객을 유도할 수 있습니다.
        <br />
        <br />
        또한, KTP 가맹점을 대상으로 무료로 제공드리고 있는 택스리펀드 스티커,
        유인물 등의 홍보물은 외국인 관광객들로 하여금 택스리펀드가 가능한 신뢰할
        수 있는 매장이라는 브랜드 이미지를 전달하는 데에도 효과적입니다.
        <br />
        <br />
        정리해보면, 별도의 비용 투자 없이{' '}
        <Bold>할인 가능한 매장이라는 이미지를 전달</Bold>하여, 외국인 고객의 더
        많은 방문과 이를 통한 매출 향상 효과를 경험하실 수 있습니다
      </Content>,
      <Content>
        KTP는 모바일 기반의 택스리펀드 서비스로 별도의 기계 설치 및 연동 없이
        간편하게 도입이 가능한 서비스에요.
        <br />
        <br />
        택스리펀드 서비스 도입을 고민하셨지만 복잡한 기계, 어려운 사용법으로
        고민하셨던 소상공인 분들이라면 KTP를 통해 편하게 택스리펀드를
        도입해보세요!
        <br />
        <br />
        지금, KTP에서는 신규 가입 가맹점을 대상 무료 홍보물 제공, 마케팅 지원 등
        다양한 혜택을 드리고 있습니다 : )
      </Content>,
    ],
    detail: {
      type: 1,
      title: `택스리펀드 매장 가입 방법 및${
        isMobile ? '\n' : ' '
      } 혜택 총정리!`,
      subTitle: '매출을 올리는 KTP의 이야기를 담았습니다.',
      bannerBg: checkWebpSupport(Policy2Detail2Webp, Policy2Detail2),
      contentTitle: [
        '',
        '🔍 택스리펀드가 뭔가요?',
        '🔍 택스리펀드 매장이 되는 방법은?',
        '🔍 택스리펀드 매장의 혜택이 있나요?',
        '❗ 지금 가입하세요!',
      ],
      image: [
        checkWebpSupport(Policy2Detail1Webp, Policy2Detail1),
        checkWebpSupport(Policy2Detail2Webp, Policy2Detail2),
        checkWebpSupport(Policy2Detail3Webp, Policy2Detail3),
        checkWebpSupport(Policy2Detail4Webp, Policy2Detail4),
        checkWebpSupport(Policy2Detail5Webp, Policy2Detail5),
      ],
    },
  },
  {
    id: 3,
    title: '노란우산공제 혜택 6가지!',
    subTitle: '매출을 올리는 KTP의 이야기를 담았습니다.',
    createDate: '2023.02.12',
    image: checkWebpSupport(Policy3Detail1Webp, Policy3Detail1),
    alt: '정책 프로필.',
    keyword: '노란우산공제 혜택 6가지!',
    isPolicy: true,
    content: [
      <Content>
        안녕하세요 😀
        <br />
        <br />
        매출을 올리는 똑똑한 변화,
        <br />
        평생 무료, 약정 없는 택스리펀드 서비스 KTP 입니다.
        <br />
        <br />
        오늘 소개해드릴 내용은 소기업·소상공인 사장님들만 누릴 수 있는 혜택,
        <br />
        <Bold>노란우산공제</Bold>에 대해 함께 알아보고자 해요!
      </Content>,
      <Content>
        소기업 및 소상공인 사업주 분들이라면 한 번쯤 들어보셨을 노란우산공제는
        폐업, 노령 등으로 생계의 위협이 발생할 때, 생활 안정과 사업 재기의
        기회를 제공받기 위해 중소기업협동조합법에 의거하여 중소기업중앙회가 관리
        및 운용하고 있는 공제제도입니다! 소기업 및 소상공인 사업주분들을 위한
        적금으로 이해해주시면 보다 쉽게 이해하실 수 있어요 : )
        <br />
        <br />
        코로나19 장기화, 경기 불황 등으로 점점 더 어려워지고 있는데 적금까지
        들어야 하나요?
        <br />
        <br />
        노란우산은 매월 돈이 나가는 적금의 형태로 지금과 같이 어려운 시기에 굳이
        가입해야 하나 싶은 사업주분들이 많으실 텐데요. 노란우산공제는 사업주
        분들의 경제적 안전을 목적으로 운용되는 공제로,{' '}
        <Bold>
          공제 가입 시 세제 혜택과 상해보험 지원 등 다양한 혜택을 받으실 수
          있다는 점,
        </Bold>{' '}
        꼭 기억해주세요!
      </Content>,
      <Content>
        소기업 및 소상공인 사업주분들만 누릴 수 있는 노란우산 공제의 혜택, 함께
        알아볼까요?
        <br />
        <br />
        <Bold>첫째, 법적으로 보호 받는 공제금</Bold>
        <br />
        노란우산공제로 납입된 공제금은 사업자를 보호하는 직접적인 역할을 하기
        때문에 압류와 양도, 담보 제공이 모두 불가능합니다. 또한, 법적으로 압류가
        금지되는 압류 방지 계좌인 행복지킴이통장으로 수령이 가능하기 때문에,
        예상치못한 불의의 상황에도 공제금은 보호받을 수 있습니다.
        <br />
        <br />
        <Bold>둘째, 소득공제 혜택</Bold>
        <br />
        아마도 가장 큰 혜택이 아닐까 싶은 소득공제 혜택인데요! 노란우산공제는
        연간 최대 500만원의 소득공제가 가능합니다. 기존의 타 소득 공제 상품과는
        별도로 관리되기 때문에 추가 소득 공제가 가능하다는 점이 가장 큰
        장점이에요 : )
        <br />
        <br />
        <Bold>셋째, 복리이자 적용</Bold>
        <br />
        공제 납입금은 복리 적용을 받아, 기존의 단리 적금 상품보다 더 많은 이자를
        받으실 수 있습니다.
        <br />
        <br />
        <Bold>넷째, 일시금 or 분할금 수령 선택 가능</Bold>
        <br />
        불의의 사고로 사업을 폐업하게 되셨을 때, 그동안 납입된 납부금을 일시금
        또는 분할금으로 선택하여 수령하실 수 있습니다.
        <br />
        <br />
        <Bold>다섯째, 임의 해약 환급금 90% 이내로 대출 가능</Bold>
        <br />
        자금의 운용이 중요한 사업의 특성을 고려, 노란우산공제에서는 연체가 없는
        가입자에 한해 90% 이내로 대출이 가능합니다.
        <br />
        <br />
        <Bold>여섯째, 무료 상해보험 가입 혜택</Bold>
        <br />
        상해로 인한 사망 및 후유장해 발생 시, 2년간 최고 월납입금의 150배까지
        보험금이 지급되는 상해보험을 무료로 가입할 수 있습니다.
      </Content>,
      <Content>
        노란우산공제는 소기업 및 소상공인 사업주 분들을 위한 제도로 가입 자격은
        아래와 같습니다 : )
        <br />
        <br />
        · 소기업 및 소상공인 범위에 포함되는 개인 사업자, 법인의 대표자
        <br />
        · 제조·건설·광업·운수업의 경우, 상시 근로자 수가 50인(10인) 미만
        <br />
        · 기타 업종의 경우 10인(5인) 미만
        <br />
        <br />
        다만, 주점업(일반유흥주점업, 무도유흥주점업, 단란주점업)과 기타
        업종(무도장 운영업, 도박장 운영업, 의료행위 아닌 안마업)은 가입이
        불가하는 점 꼭 확인해주세요!
      </Content>,
      <Content>
        노란우산공제는 아래 3가지 방법으로 신청을 하실 수 있습니다 : )
        <br />
        <br />·{' '}
        <a href='https://www.8899.or.kr/yuma/index.do'>
          노란우산 홈페이지 가입 및 신청
        </a>
        <br />
        · 중소기업중앙회 유선 신청(1666-9988)
        <br />· 가까운 은행 및 중소기업중앙회 방문
      </Content>,
      <Content>
        노란우산공제처럼 소상공인 및 자영업자 사장님이라면 누구나 신청하고
        혜택을 받을 수 있는 제도가 하나 더 있습니다!
        <br />
        <br />
        바로, 강력한 외국인 고객 유치효과로 매출을 효과적으로 향상시킬 수 있는
        <Bold>택스리펀드(사후면세제도)</Bold>
        인데요.
        <br />
        <br />
        택스리펀드는{' '}
        <Bold>일반 판매 사업장도 공항의 면세점과 같이 면세 판매 자격</Bold>을
        얻을 수 있게 하는 제도로, 외국인 관광객 고객은 면세 가격으로 구매할 수
        있는 택스리펀 매장을 더 선호하는데요. 관광객은 부가세가 제외된 면세
        가격으로 구매할 수 있어 혜택을 받고, 사업주 분들은 별도의 비용 투자 없이
        할인 프로모션을 제공해 강력한 마케팅 효과를 경험하실 수 있습니다.
        <br />
        <br />
        지원 정책을 알뜰히 챙기시는 똑똑한 사장님들이시라면, 택스리펀드도 꼭 한
        번 살펴보세요 : )
        <br />
        (아래 배너를 클릭하시면 한눈에 읽어보실 수 있습니다!)
      </Content>,
    ],
    detail: {
      type: 1,
      title: '노란우산공제 혜택 6가지!',
      subTitle: '매출을 올리는 KTP의 이야기를 담았습니다.',
      bannerBg: checkWebpSupport(Policy3Detail2Webp, Policy3Detail2),
      contentTitle: [
        '',
        '🔍 노란우산공제란?',
        '🔍 어떤 혜택이 있나요?',
        '🔍 누구나 가입 가능한가요?',
        '🔍 가입 방법은?',
        '🔍 도움이 되는 또 하나의 제도, 택스리펀드',
      ],
      image: [
        checkWebpSupport(Policy3Detail1Webp, Policy3Detail1),
        checkWebpSupport(Policy3Detail2Webp, Policy3Detail2),
        checkWebpSupport(Policy3Detail3Webp, Policy3Detail3),
        checkWebpSupport(Policy3Detail4Webp, Policy3Detail4),
        checkWebpSupport(Policy3Detail5Webp, Policy3Detail5),
        checkWebpSupport(Policy3Detail6Webp, Policy3Detail6),
      ],
    },
  },
  {
    id: 4,
    title: '희망리턴패키지 총정리',
    subTitle: '매출을 올리는 KTP의 이야기를 담았습니다.',
    createDate: '2023.02.16',
    image: checkWebpSupport(Policy4Detail1Webp, Policy4Detail1),
    alt: '정책 프로필.',
    keyword: '희망리턴패키지 총정리',
    isPolicy: true,
    content: [
      <Content>
        안녕하세요 😀
        <br />
        <br />
        매출을 올리는 똑똑한 변화,
        <br />
        평생 무료 택스리펀드 서비스 KTP 입니다.
        <br />
        <br />
        오늘 소개해드릴 내용은 경영위기에 처해있거나, 폐업을 하신 소상공인
        분들의 위기극복과 재기를 지원하는
        <br />
        2023 새로 바뀐 희망리턴패키지에 대한 내용입니다 : )
      </Content>,
      <Content>
        희망리턴패키지는 최근 코로나19와 경기 침체로 인해, 폐업 혹은 폐업을
        고려하고 계신 소상공인분들의 부담을 경감하고 함께 극복할 수 있도록 경영
        컨설팅과 사업화 자금까지 지원하는 중소기업벤처부의 지원사업이에요!
        2022년 희망리턴패키지 사업에 정말 많은 분들이 참여해주셨는데요.
        2023년에도 소상공인 분들을 위해, 더 많은 예산으로 시행된다고 합니다.
        어떤 지원 항목들이 있는지 함께 알아볼까요?
        <br />
        <br />
        <Bold>· 경영개선 지원</Bold>
        <br />: 전문가의 현장방문을 통한 경영상태 진단, 사업화 자금(최대
        2천만원) 지원
        <br />
        <Bold>· 원스톱폐업지원</Bold>
        <br />: 사업 재기 전략 및 세무, 부동산 컨설팅과 점포철거 비용 지원
        <br />
        <Bold>· 재취업지원</Bold>
        <br />: 폐업 후, 취업을 위한 교육 및 수당 지원
        <br />
        <Bold>· 재창업지원</Bold>
        <br />: 재창업을 위한 경영 교육 및 기술 실습, 사업화 자금(최대 2천만원)
        지원
      </Content>,
      <Content>
        앞서 말씀드린 4가지 지원 항목들을 하나하나 세부적으로 알아볼까 하는데요!
        <br />
        <br />
        먼저, 경영개선 지원사업은 아직 폐업을 하지 않은 소상공인 사업주 분들을
        대상으로 경영개선을 위한 컨설팅과 사업화 자금을 지원해주는
        지원사업이에요. 특히, 사업화자금의 경우에는 최대 2천만원까지 지원을 받을
        수 있어, 매출 저하 혹은 경영위기가 걱정이신 사업주 분들에게는 희소식이
        아닐 수 없는데요. 경영개선 지원사업 적용의 기준은 아래와 같습니다.
        <br />
        <br />
        · 공고일 기준, 신청자 명의 사업체를 운영 중인 자
        <br />
        · 전년 대비 매출액 20% 감소 혹은 최근 3년간 지속적인 매출 감소 중인 자
        <br />
        · 공고일 기준, 신청자 명의 사업체를 운영 중인 자
        <br />· 공고일 기준, 신청자 명의 사업체를 운영 중인 자
        <br />
        <br />
        경영개선 지원사업은{' '}
        <Bold>
          ‘진단신청 → 경영진단(현장방문) → 지원사업 연계(경영개선, 사업화,
          폐업지원 등)’
        </Bold>
        의 순서로 진행이 되는데요. 특히, 사업화가 필요하다고 판단될 시에는{' '}
        <Bold>최대 2천만원의 사업화 자금을 지원</Bold>받으실 수가 있다는 점!
      </Content>,
      <Content>
        원스톱폐업 지원사업은 폐업을 준비하고 있는 소상공인 분들에게 폐업의 전
        과정을 지원해드리는 사업이에요. 경영 상의 문제로 폐업을 결심하게 되지만,
        사업주 분들을 더 힘들게 하는 것은 복잡하고 어려운 폐업의 과정인데요.
        세무 및 부동산의 정리부터 점포 철거, 그리고 채무조정까지, 정말 쉽지가
        않은 과정이에요. 하지만 원스톱폐업 지원사업을 통해 보다 안전하게 폐업을
        진행하실 수가 있습니다. 먼저, 신청자격에 대해 알아볼까요?
        <br />
        <br />
        · 공고일 기준, 폐업하였거나 폐업 예정인 자
        <br />
        · 사업개시일이 60일 경과된 소상공인
        <br />
        · 도박, 유흥업 등 제외 종목에 해당하지 않는 자
        <br />
        <br />위 사항에 해당 되신다면, 세무 및 부동산 등의 종합 컨설팅을 받으실
        수 있는 사업정리컨설팅, 전담 변호사 배정을 통한 법률 자문 지원, 신용
        전문가의 채무조정 및 솔루션을 제공받을 수 있는 채무조정, 1평 기준 13만원
        이내의 철거 비용을 지원받을 수 있는 점포철거지원,{' '}
        <Bold>총 4가지 지원항목을 모두 지원</Bold>받으실 수 있습니다.
      </Content>,
      <Content>
        폐업을 하고 재창업을 계획하시는 사업주 분들도 계시겠지만, 다시 취업을
        준비하시게 되는 사업주 분들도 꽤나 많으신데요. 이렇게 재취업을
        준비하시는 사업주 분들에게 전직에 대한 기초교육부터, 심화교육까지 모든
        과정을 지원하는 재취업 지원사업도 희망리턴패키지에 포함이 되어 있습니다.
        특히, 재취업 지원사업을 통해 전직을 준비하시게되면, 생활비에 보탬이 될
        수 있는 전직장려수당을 지원받으실 수 있다는 점! 자격 기준부터
        알아볼까요?
        <br />
        <br />
        · 공고일 기준, 폐업하였거나 폐업 예정인 자
        <br />
        · 사업개시일이 60일 경과된 소상공인
        <br />
        · 도박, 유흥업 등 제외 종목에 해당하지 않는 자
        <br />
        <br />위 사항에 해당 되신다면 취업기초교육을 지원하는 전직기초교육과 1:1
        직업상담과 모의면접실습 등의 전직심화교육, 현장실습 교육까지 지원하는
        전직특화교육까지 <Bold>취업에 필요한 모든 과정을 지원</Bold> 받으실 수가
        있습니다.
      </Content>,
      <Content>
        마지막으로 재창업 지원사업은 성장 가능성이 높은 폐업 혹은 폐업 예정의
        소상공인 사업주분들에게 경영 컨설팅과 멘토링, 그리고 사업화자금을
        지원하는 지원사업이에요. 사업의 아이템은 우수하지만 외적인 요인(코로나
        19, 경기침체 등)에 의해 폐업을 하게 된 소상공인 분들이시라면, 다시금
        사업의 꿈을 펼칠 수 있는 정말 도움이 많이 되는 지원사업이라고 생각이
        되는데요. 지원 자격은 아래와 같습니다!
        <br />
        <br />
        · 공고일 기준 사업자등록이 되어 있지 않은 자
        <br />
        · 사업개시일이 60일 경과된 소상공인
        <br />
        · 재창업 의지와 성장 가능성이 높은 소상공인
        <br />
        <br />
        재창업 지원사업에 선정이 되시면, 기존 사업의 폐업 원인분석과 재창업
        업종의 사업 컨설팅을 포함하여 사업의 전과정의 멘토링을 지원받으실 수
        있는데요. 또한, 사업화를 위한 자금을 최대 2천만원까지 국비로 지원받으실
        수가 있습니다!
      </Content>,
      <Content>
        경영위기를 극복하고자 하는 사업주 분들에게 도움이 될 또 하나의 제도가
        있는데요!
        <br />
        <br />
        바로, 별도의 비용 투자 없이 강력한 외국인 고객 유치효과로 매출을
        효과적으로 향상시킬 수 있는 <Bold>택스리펀드(사후면세제도)</Bold>입니다.
        <br />
        <br />
        택스리펀드는{' '}
        <Bold>일반 판매 사업장도 공항의 면세점과 같이 면세 판매 자격</Bold>을
        얻을 수 있게 하는 제도로, 외국인 관광객 고객은 면세 가격으로 구매할 수
        있는 택스리펀 매장을 더 선호하는데요. 관광객은 부가세가 제외된 면세
        가격으로 구매할 수 있어 혜택을 받고, 사업주 분들은 별도의 비용 투자 없이
        할인 프로모션을 제공해 강력한 마케팅 효과를 경험하실 수 있습니다.
        <br />
        <br />
        지원 정책을 알뜰히 챙기시는 똑똑한 사장님들이시라면, 택스리펀드도 꼭 한
        번 살펴보세요 : )
        <br />
        (아래 배너를 클릭하시면 한눈에 읽어보실 수 있습니다!)
      </Content>,
    ],
    detail: {
      type: 1,
      title: '희망리턴패키지 총정리',
      subTitle: '매출을 올리는 KTP의 이야기를 담았습니다.',
      bannerBg: checkWebpSupport(Policy4Detail2Webp, Policy4Detail2),
      contentTitle: [
        '',
        '🔍 희망리턴패키지란?',
        '🔍 첫째, 경영개선 지원',
        '🔍 둘째, 원스톱폐업 지원',
        '🔍 셋째, 재취업 지원',
        '🔍 넷째, 재창업지원',
        '🔍 도움이 되는 또 하나의 제도, 택스리펀드',
      ],
      image: [
        checkWebpSupport(Policy4Detail1Webp, Policy4Detail1),
        checkWebpSupport(Policy4Detail2Webp, Policy4Detail2),
        checkWebpSupport(Policy4Detail3Webp, Policy4Detail3),
        checkWebpSupport(Policy4Detail4Webp, Policy4Detail4),
        checkWebpSupport(Policy4Detail5Webp, Policy4Detail5),
        checkWebpSupport(Policy4Detail6Webp, Policy4Detail6),
        checkWebpSupport(Policy4Detail7Webp, Policy4Detail7),
      ],
    },
  },
  {
    id: 5,
    title: '보험료 최대 80% 지원',
    subTitle: '매출을 올리는 KTP의 이야기를 담았습니다.',
    createDate: '2023.02.20',
    image: checkWebpSupport(Policy5Detail1Webp, Policy5Detail1),
    alt: '정책 프로필.',
    keyword: '보험료 최대 80% 지원 두루누리 사회보혐료 사업',
    isPolicy: true,
    content: [
      <Content>
        안녕하세요 😀
        <br />
        <br />
        매출을 올리는 똑똑한 변화,
        <br />
        평생 무료 택스리펀드 서비스 KTP 입니다.
        <br />
        <br />
        오늘도 소상공인 사업주 분들을 위해 정말 도움이 될 지원정책을 소개하고자
        하는데요.
        <br />
        은근히 부담되는 <Bold>고용보험료와 국민연금을 최대 80% 지원</Bold>받을
        수 있는 <Bold>두루누리 사회보험료 지원사업</Bold>입니다!
        <br />
        <br />
        2023년도에 달라지는 새로운 부분도 꼼꼼하게 알려드릴테니, 꼭 챙기시길
        바라겠습니다!
      </Content>,
      <Content>
        두루누리 지원사업은 소규모 사업장을 운영하는 소상공인 분들이 사회의
        안전망이라고 할 수 있는 사회보험(고용보험, 국민연금)의 사각지대에 놓이지
        않도록, 사회보험료의 일부분을 지원하는 지원사업입니다. 고용보험과
        국민연금이 필요하다는 것은 알고 있지만, 매월 납입하는 납입금의 부담으로
        가입을 주저하셨던 소상공인 사업주분들은 이번 기회를 통해 꼭 확인해보시길
        바래요!
      </Content>,
      <Content>
        고용보험료와 국민연금은 장기간, 매월 납입되는 부담이 있는데요. 두루누리
        사회보험료 지원사업은 이러한 특성을 고려하여 36개월이라는 긴 기간 동안
        보험료를 지원해주는 정말 든든한 지원사업이에요. 특히, 납입금의 80%라는
        높은 비율로 지원을 받을 수 있으며, 동시에 사업자 뿐 아니라 근로자까지
        혜택을 받으실 수 있다는 점!
        <br />
        <br />
        예를 들어 근로자의 월평균 보수가 200만원일 경우에는 매월 84,800원 가량을
        총 36개월 동안 지원받으실 수 있는데요. 본인 부담금은 약 21,600원 정도로
        부담없는 금액으로 미래를 준비할 수 있는 사회보험료를 납입할 수 있습니다
        : )
      </Content>,
      <Content>
        두루누리 사회보험료 지원사업은 소규모 사업을 운영하는 사업주와 소속
        근로자를 대상으로 하는 사업으로 지원 자격은 아래와 같습니다.
        <br />
        <br />
        · 근로자 수 10명 미만의 사업에 고용된 근로자 중,
        <br />
        <Tab />
        월평균 보수가 260만원 미만인 근로자와 사업주
        <br />
        · 고용보험과 국민연금의 신규 가입자인 자
        <br />
        <Tab />
        *직전 6개월 자격취득 이력이 없는 자
        <br />· 외국인 근로자도 지원대상에 포함
        <br />
        <br />
        사업장 기준으로는 근로자 수가 10명 미만, 근로자 기준으로는 월평균
        보수액이 220만원 미만일 경우에 신청이 가능합니다. 두루누리 사회보험료
        지원사업에 신청하시게 되면 사업주가 월별보험료를 납부하였는지를 확인한
        뒤, 그 다음 달 보험료에서 해당 월의 지원금을 뺀 나머지 금액을 고지하는
        방법으로 지원되고 있습니다 : )
      </Content>,
      <Content>
        위 지원 자격에 해당이 되고, 동시에 신청 가능한 근로자가 있을 경우에는{' '}
        <a href='https://www.4insure.or.kr/ins4/ptl/Main.do?urlkind=mobile'>
          4대사회보험 정보연계센터
        </a>
        에 접속을 하신 후, 사업장 회원으로 로그인하시어 신청이 가능합니다.
        <br />
        <br />· 사회보험 미가입 사업장일 경우
        <br />
        <Tab />- 사업장 업무 &gt; 성립신고, 두루누리보험료지원 체크 <br />·
        사회보험 기가입 사업장일 경우
        <br />
        <Tab />- 사업장 업무 &gt; 두루누리보험료지원
        <br />
        <br />
        두루누리 사회보험료 지원사업 신청에 관해 보다 자세한 사항이 궁금하실
        경우, 근로복지공단(1588-0075) 또는 국민연금공단(1355)로 확인이
        가능합니다 : )
      </Content>,
      <Content>
        두루누리 사회보험료 지원사업과 같이, 소상공인 분들에게 도움이 될 유익한
        정보!
        <br />
        <br />
        바로, 별도의 비용 투자 없이 강력한 외국인 고객 유치효과로 매출을
        효과적으로 향상시킬 수 있는 <Bold>택스리펀드(사후면세제도)</Bold>입니다.
        <br />
        <br />
        택스리펀드는{' '}
        <Bold>일반 판매 사업장도 공항의 면세점과 같이 면세 판매 자격</Bold>을
        얻을 수 있게 하는 제도로, 외국인 관광객 고객은 면세 가격으로 구매할 수
        있는 택스리펀 매장을 더 선호하는데요. 관광객은 부가세가 제외된 면세
        가격으로 구매할 수 있어 혜택을 받고, 사업주 분들은 별도의 비용 투자 없이
        할인 프로모션을 제공해 강력한 마케팅 효과를 경험하실 수 있습니다.
        <br />
        <br />
        지원 정책을 알뜰히 챙기시는 똑똑한 사장님들이시라면, 택스리펀드도 꼭 한
        번 살펴보세요 : )
        <br />
        (아래 배너를 클릭하시면 한눈에 읽어보실 수 있습니다!)
      </Content>,
    ],
    detail: {
      type: 1,
      title: '보험료 최대 80% 지원',
      subTitle: '매출을 올리는 KTP의 이야기를 담았습니다.',
      bannerBg: checkWebpSupport(Policy5Detail2Webp, Policy5Detail2),
      contentTitle: [
        '',
        '🔍 두루누리 지원사업이란?',
        '🔍 지원 혜택부터 알아볼까요?',
        '🔍 지원 자격은?',
        '🔍 어떻게 신청하나요?',
        '🔍 도움이 되는 또 하나의 제도, 택스리펀드',
      ],
      image: [
        checkWebpSupport(Policy5Detail1Webp, Policy5Detail1),
        checkWebpSupport(Policy5Detail2Webp, Policy5Detail2),
        checkWebpSupport(Policy5Detail3Webp, Policy5Detail3),
        checkWebpSupport(Policy5Detail4Webp, Policy5Detail4),
        checkWebpSupport(Policy5Detail5Webp, Policy5Detail5),
        checkWebpSupport(Policy5Detail6Webp, Policy5Detail6),
      ],
    },
  },
];
