import styled, { css } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import LogoImg from 'assets/common/ktp_logo.png';
import WhiteLogoImg from 'assets/common/ktp_logo_white.png';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

interface IProps {
  isTop: boolean;
  isMobile?: boolean;
}
function Logo({ isTop, isMobile }: IProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const visitedInfo = useSelector((state: RootState) => state.visitedInfo);

  const checkMovieBannerURL = ['/medical', '/'].includes(pathname);

  const onClick = () => {
    switch (visitedInfo || pathname) {
      case '/hotel':
        navigate(routes.hotel);
        break;
      case '/medical':
        navigate(routes.medical);
        break;
      case '/':
        navigate(routes.home);
        break;
      default:
        navigate(routes.home);
    }
    window.scrollTo(0, 0);
  };
  return (
    <LogoWrapper isMobile={isMobile}>
      <SLogo
        alt='Korea Tax Free Payments.'
        src={LogoImg}
        onClick={onClick}
        width='100%'
        height='100%'
      />
    </LogoWrapper>
  );
}

const LogoWrapper = styled.div<{ isMobile?: boolean }>`
  position: relative;
  width: 130.52px;
  height: 34px;
  @media ${(props) => props.theme.tablet} {
    width: 99.81px;
    height: 26px;
  }
  ${(props) =>
    props.isMobile &&
    css`
      width: 99.81px !important;
      height: 26px !important;
    `}
`;

const SLogo = styled.img`
  object-fit: cover;
  cursor: pointer;
`;
export default Logo;
