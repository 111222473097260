import React from 'react';

import ApplyIconStep from './ApplyIconStep';
import ApplyImageStep from './ApplyImageStep';

import { ServiceJoinContent } from 'constants/ServiceJoin';

function ApplyNowFlow() {
  const {
    iconStep: {
      ApplyNow: { title, titleStrength, items },
    },
    imageStep: { ApplyNow },
  } = ServiceJoinContent;

  return (
    <>
      <ApplyIconStep
        title={title}
        titleStrength={titleStrength}
        items={items}
      />
      <ApplyImageStep items={ApplyNow.items} />
    </>
  );
}

export default ApplyNowFlow;
