import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';
import useDeviceCheck from './useDeviceCheck';

export function useKTPExample(
  images: string[],
  currentIndex: number,
  setCurrentIndex: Dispatch<SetStateAction<number>>,
  idx: number,
) {
  const [isActive, setIsActive] = useState(false);
  let timeOutRef = useRef<NodeJS.Timeout | null>(null);
  const slideRef = useRef<HTMLDivElement>(null);
  const { isTablet } = useDeviceCheck();

  useEffect(() => {
    if (!isActive) {
      return;
    }
    clearTimeout(timeOutRef.current!);
    refundSlide(images);
  }, [currentIndex, isActive]);

  const refundSlide = (images: string[]) => {
    timeOutRef.current = setTimeout(() => {
      if (!slideRef.current) {
        return;
      }
      if (idx === 2) {
        slideRef.current.style.transform = `translateY(calc(${
          -currentIndex * (isTablet ? 390 : 500)
        }px))`;
      } else {
        slideRef.current.style.transform = `translateX(calc(${
          -currentIndex * (isTablet ? 200 : 240)
        }px))`;
      }
      if (currentIndex === 0) {
        slideRef.current.style.transition = 'transform 0s ease-out';
      } else {
        slideRef.current.style.transition = 'transform 0.3s ease-out';
      }
      const nextIndex =
        currentIndex >= images.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(nextIndex);
    }, 2000);
  };

  return { slideRef, setIsActive, isActive };
}
