import { isMobile, isAndroid, isIOS, isSafari } from 'react-device-detect';
import { Dispatch, SetStateAction } from 'react';

export function CalculateBox(
  targetArr: [number, number?, number?, number?],
): string {
  let resultArr: [number, number, number, number] = [
    targetArr[0],
    targetArr[0],
    targetArr[0],
    targetArr[0],
  ];

  if (targetArr.length === 2) {
    if (targetArr[1] !== undefined)
      resultArr = [targetArr[0], targetArr[1], targetArr[0], targetArr[1]];
  } else if (targetArr.length === 3) {
    if (targetArr[1] !== undefined && targetArr[2] !== undefined)
      resultArr = [targetArr[0], targetArr[1], targetArr[2], targetArr[1]];
  } else if (targetArr.length === 4) {
    if (
      targetArr[1] !== undefined &&
      targetArr[2] !== undefined &&
      targetArr[3] !== undefined
    )
      resultArr = [targetArr[0], targetArr[1], targetArr[2], targetArr[3]];
  }

  return `${resultArr.toString().replace(/,/gi, 'px ')}px`;
}
export const addComma = (num: string | number) =>
  String(num).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// Graph 색지정
export const getGraphColor = (index: number) => {
  return ['#7CA7FA', '#5089F8', '#246CF6', '#1856CD'][index];
};

export const maillCopy = (setIsMailCopy: Dispatch<SetStateAction<boolean>>) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText('ktp@ktaxpay.com')
      .then(() => {
        if (isMobile && isAndroid) {
          alert('이메일 주소 복사가 완료되었습니다.');
        } else if (isMobile && isIOS) {
          return;
        } else {
          setIsMailCopy(true);
        }
      })
      .catch(() => {
        setIsMailCopy(false);
      });
  } else {
    if (isMobile) {
      alert(
        '메일 주소 복사를 지원하지 않는 브라우저입니다.\n직접 입력하거나 PC로 다시 시도해주세요.',
      );
    } else {
      setIsMailCopy(false);
    }
  }
};

export const applayDownload = () => {
  const fileUrls = [
    {
      url: 'https://ktp-image.s3.ap-northeast-2.amazonaws.com/KtpGlobalPay/agreement.pdf',
      filename: '글로벌결제(큐릭) 이용 신청서.pdf',
    },
    {
      url: 'https://ktp-image.s3.ap-northeast-2.amazonaws.com/KtpGlobalPay/application.pdf',
      filename: '글로벌결제(큐릭) 이용 계약서.pdf',
    },
  ];

  function downloadFile(url: string, filename: string) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = filename;
        anchor.click();
      })
      .catch(() => alert('다운로드에 실패 하였습니다.\n문의 02-6275-8011'));
  }

  fileUrls.forEach((file) => {
    if (isSafari) {
      const downloadConfirmed = window.confirm(
        `'${file.filename}' 파일을 다운로드하시겠습니까?`,
      );
      if (downloadConfirmed) {
        downloadFile(file.url, file.filename);
      }
    } else {
      downloadFile(file.url, file.filename);
    }
  });
};

export const InquiryLabel = (pathname: string) => {
  return pathname === '/inquiry' ? '병원' : '매장';
};
