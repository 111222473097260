import { useRef, useState } from 'react';
import styled from 'styled-components';
import ICON_ARROW_BLUE from 'assets/refund/taxRefund/arrow_blue.png';
import ICON_ARROW_WHITE from 'assets/medical/whiteArrow.png';
import ICON_CS_BLUE from 'assets/medical/CSBlue.png';
import FlexWrap from 'components/_common/FlexWrap';
import LazyImage from 'components/_common/LazyImage';
import Typography from 'components/_common/Typography';
import useDeviceCheck from 'hooks/useDeviceCheck';

type ProcessItemProps = {
  item: {
    title: string;
    linkUrl?: string;
    linkLabel?: string;
    keyword: string;
    content: {
      explain: string;
      items?: Array<string>;
    };
    image: string;
    notification?: string;
    number?: string;
  };
  idx: number;
  length: number;
};

function MedicalGuideItem({ item, idx }: ProcessItemProps) {
  const [arrowIcon, setArrowIcon] = useState(ICON_ARROW_BLUE);
  const divRef = useRef<HTMLDivElement>(null);
  const { title, keyword, content, image, linkUrl, linkLabel } = item;
  const { isTablet } = useDeviceCheck();

  return (
    <Container ref={divRef} isReverse={(idx + 1) % 2 === 0}>
      <ImgWrapper>
        <LazyImage src={image} alt='도입 방법.' />
      </ImgWrapper>
      <ContentContainer>
        <TopContent>
          <Typography size='1.3rem' fontWeight='500' color='#1856CD'>
            {keyword}
          </Typography>
          <Typography
            size={isTablet ? '1.5rem' : '1.75rem'}
            fontWeight='700'
            color='#191919'
          >
            {title}
          </Typography>
          <Typography
            size={isTablet ? '0.9rem' : '1rem'}
            fontWeight='500'
            color='#80848a'
          >
            {item?.notification}
          </Typography>
        </TopContent>
        <BottomContent>
          <Typography size='1rem' fontWeight='500' color='#191919'>
            {content.explain}
          </Typography>
        </BottomContent>
        {item?.number && (
          <NumberNotice>
            <img src={ICON_CS_BLUE} alt='' width={24} height={24} />
            <LinkTxt>{item.number}</LinkTxt>
          </NumberNotice>
        )}
        {linkUrl && (
          <Link
            href={linkUrl}
            target='_blank'
            onMouseOver={() => setArrowIcon(ICON_ARROW_WHITE)}
            onMouseOut={() => setArrowIcon(ICON_ARROW_BLUE)}
          >
            <LinkTxt>{linkLabel}</LinkTxt>
            <LinkImg src={arrowIcon} alt='' />
          </Link>
        )}
      </ContentContainer>
    </Container>
  );
}

export default MedicalGuideItem;

const ImgWrapper = styled.div`
  width: 23rem;
  /* height: 18rem; */
  @media ${(props) => props.theme.tablet} {
    width: 100%;
    max-width: 400px;
  }
`;

const Container = styled.div<{ isReverse: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isReverse ? 'row-reverse' : 'row')};
  width: 100%;
  gap: 10rem;
  padding: 6.75rem 0;
  @media ${(props) => props.theme.tablet} {
    gap: 0;
    flex-direction: column;
    padding: 2.81rem 0;
    align-items: center;
  }
`;

const ContentContainer = styled(FlexWrap)`
  flex-direction: column;
  justify-content: center;
  flex: 1;
  @media ${(props) => props.theme.tablet} {
    margin-top: 2.64rem;
    align-items: center;
    width: 100%;
  }
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  margin-bottom: 24px;
  @media ${(props) => props.theme.tablet} {
    text-align: center;
    white-space: normal;
  }
`;

const BottomContent = styled(FlexWrap)`
  flex-direction: column;
  white-space: pre-wrap;
  gap: 12px;
  margin-bottom: 40px;
  @media ${(props) => props.theme.tablet} {
    align-items: center;
    text-align: center;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 1000px;
  border: 1px solid #1856cd;
  padding: 4px 24px;
  width: fit-content;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: -0.42px;
  color: #1856cd;
  transition: all 0.2s;
  &:hover {
    background-color: #1856cd;
    color: #fff;
  }
  @media ${(props) => props.theme.tablet} {
    margin: 1rem auto;
  }
`;

const LinkTxt = styled.span`
  margin-top: 3px;
`;

const LinkImg = styled.img`
  width: 18px;
  height: 18px;
`;

const NumberNotice = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 1000px;
  border: 1px solid #1856cd;
  padding: 4px 24px;
  width: fit-content;
  font-size: 1.3rem;
  font-weight: 700;
  letter-spacing: -0.42px;
  color: #1856cd;
  @media ${(props) => props.theme.tablet} {
    margin: 1rem auto;
  }
`;
