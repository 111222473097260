import { useCallback, useState } from 'react';
import styled from 'styled-components';

import ChevronUP from 'assets/medical/ChevronUp.png';
import ChevronDown from 'assets/medical/ChevronDown.png';
import { Link } from 'react-router-dom';

interface IProps {
  question: string;
  answer: string;
}

function QeustionItem({ question, answer }: IProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onToggleHide: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      setIsOpen((prevState) => !prevState);
    },
    [],
  );

  const LinksToText = (text: string): JSX.Element => {
    const urlRegex = /(https?:\/\/[^\s()]+)/g;
    return (
      <span>
        {text.split(urlRegex).map((part, index) => {
          if (part.match(urlRegex)) {
            return (
              <StyledLink
                key={index}
                to={part}
                target='_blank'
                rel='noopener noreferrer'
              >
                {part}
              </StyledLink>
            );
          } else {
            return part;
          }
        })}
      </span>
    );
  };

  return (
    <Wrapper>
      <QuestionWrapper onClick={onToggleHide}>
        <Question>{question}</Question>
        <ChevronIcon src={isOpen ? ChevronUP : ChevronDown} />
      </QuestionWrapper>
      <AnswerWrapper isOpen={isOpen}>
        <Answer isOpen={isOpen}>{LinksToText(answer)}</Answer>
      </AnswerWrapper>
    </Wrapper>
  );
}

export default QeustionItem;

const Wrapper = styled.li`
  position: relative;
  list-style: none;
  border-bottom: 1px solid #cbccce;
`;

const QuestionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1.56rem;
  cursor: pointer;
`;

const Question = styled.p`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.03rem;
  @media ${(props) => props.theme.tablet} {
    font-size: 1.125rem;
  }
`;

const ChevronIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

const AnswerWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  background-color: #f5f6f7;
  overflow: hidden;
  transition: all 300ms;
  max-height: ${(props) => (props.isOpen ? '100%' : '0')};
  padding: ${(props) => (props.isOpen ? '1rem 2rem' : '0 2rem')};
`;

const Answer = styled.p<{ isOpen: boolean }>`
  color: #5f6165;
  font-size: 1.125rem;
  line-height: 150%;
  letter-spacing: -0.0225rem;
  transition: all 300ms;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  white-space: pre-line;
  @media ${(props) => props.theme.tablet} {
    font-size: 0.875rem;
    letter-spacing: -0.0175rem;
  }
`;

const StyledLink = styled(Link)`
  color: #5f6165;
`;
