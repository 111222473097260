import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import IMG_TARGET1 from 'assets/medical/taxRefundTarget1.png';
import IMG_TARGET2 from 'assets/medical/taxRefundTarget2.png';
import { SlideUp } from 'animation';
import useObserve from 'hooks/useObserve';

const TARGET_DATA = [
  {
    target: '외국인',
    notification: '국내 체류 6개월 미만',
    img: IMG_TARGET1,
  },
  {
    target: '재외국민',
    notification: (
      <>
        해외 2년 이상 거주
        <br />
        국내 3개월 미만 거주
      </>
    ),
    img: IMG_TARGET2,
  },
];

function MedicalTaxRefundTarget() {
  const { isActive, ref: titleRef } = useObserve();
  const { isActive: cardIsActive, ref: CardRef } = useObserve();

  return (
    <Wrapper>
      <Title ref={titleRef} isActive={isActive}>
        의료 시술 후 택스리펀이 가능한 대상자는
        <br />
        아래와 같아요.
      </Title>
      <CardWrapper ref={CardRef} isActive={cardIsActive}>
        {TARGET_DATA.map((item) => {
          return (
            <CardContainer>
              <CardContentsWrapper>
                <CardTitle>{item.target}</CardTitle>
                <Notification>{item.notification}</Notification>
              </CardContentsWrapper>
              <img src={item.img} alt='' width={143} />
            </CardContainer>
          );
        })}
      </CardWrapper>
      <NotificationWrapper>
        <Notification>* 단, 시술일로부터 3개월 이내 출국 필수</Notification>
      </NotificationWrapper>
    </Wrapper>
  );
}

export default MedicalTaxRefundTarget;

const Wrapper = styled.section`
  padding: 200px 0;
  background: #f4f8ff;
`;

const Title = styled.h2<{ isActive: boolean }>`
  margin-bottom: 80px;
  padding: 0 17.5px;
  font-size: 3rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.96px;
  color: #030303;
  text-align: center;
  position: relative;
  opacity: 0;
  word-break: keep-all;
  opacity: 0;
  ${(props) =>
    props.isActive &&
    css`
      animation: ${SlideUp} 400ms ease-in forwards;
    `}
  @media ${(props) => props.theme.tablet} {
    font-size: 2.5rem;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 2rem;
  }
`;

const CardWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 48px;
  width: fit-content;
  margin: auto;
  padding: 0 17.5px;
  opacity: 0;
  ${(props) =>
    props.isActive &&
    css`
      animation: ${SlideUp} 400ms ease-in forwards;
    `}
  @media ${(props) => props.theme.tablet} {
    gap: 30px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex: 1;
  padding: 36px 54px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 12px 0px #eaf1ff;

  @media ${(props) => props.theme.tablet} {
    padding: 24px;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 20px;
  }
`;

const CardContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.strong`
  color: #030303;
  font-size: 2rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.56px;
  word-break: keep-all;
  @media ${(props) => props.theme.tablet} {
    font-size: 1.5rem;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 1.2rem;
  }
`;

const Notification = styled.small`
  color: #80848a;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.36px;
  word-break: keep-all;
  white-space: nowrap;
  @media ${(props) => props.theme.tablet} {
    font-size: 1rem;
  }
  @media ${(props) => props.theme.mobile} {
    font-size: 0.9rem;
  }
`;

const NotificationWrapper = styled.div`
  margin: 80px auto 0;
  text-align: center;
`;
