import React, { useEffect, useReducer, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { serviceRoutes } from 'routes';
import styled from 'styled-components';

import InnerLayout from 'components/_common/InnerLayout';
import { FranchiseeItemContent } from 'constants/Service';
import { IFranchiseeList } from 'types/service';
import Search from './Search';
import ServiceItem from './ServiceItem';
import { useLocation } from 'react-router';

function FranchiseeContent() {
  const [isAddButton, setIsAddButton] = useState(true);
  const [franchiseeList, setFranchiseeList] = useState<IFranchiseeList[]>([]);
  const indexRef = useRef<number>(1);
  const locaiton = useLocation();

  useEffect(() => {
    initialFranchiseeList();
  }, [locaiton]);

  useEffect(() => {
    showAddButton();
  }, [franchiseeList]);

  const initialFranchiseeList = () => {
    const newArray: IFranchiseeList[] = FranchiseeItemContent.slice(
      0,
      (isMobile ? 6 : 8) * indexRef.current,
    );
    setFranchiseeList(newArray);
  };

  const showAddButton = () => {
    const isShow =
      franchiseeList.length >= (isMobile ? 6 : 8) * indexRef.current;
    setIsAddButton(isShow);
  };

  const onAdd = () => {
    indexRef.current += 1;
    initialFranchiseeList();
  };

  return (
    <>
      {!isMobile && (
        <Search
          searchList={FranchiseeItemContent}
          setSearchList={setFranchiseeList}
          placeholder='가맹점 위치 및 상품 찾기'
        />
      )}
      <Container>
        <Inner>
          <Header>
            <Title>
              <span style={{ color: '#808080' }}>
                외국인 고객까지 생각한 KTP {isMobile && <br />}
              </span>
              가맹점 소개
            </Title>
            {isMobile && (
              <Search
                searchList={FranchiseeItemContent}
                setSearchList={setFranchiseeList}
                placeholder='가맹점 위치 및 상품 찾기'
              />
            )}
          </Header>
          <FranchiseeList>
            {franchiseeList.map((item) => (
              <ServiceItem
                key={item.id}
                item={item}
                link={serviceRoutes.franchiseeDetail}
              />
            ))}
          </FranchiseeList>
          {isAddButton && <AddButton onClick={onAdd}>+ 더보기</AddButton>}
        </Inner>
      </Container>
    </>
  );
}

const Container = styled.div`
  background-color: #ffffff;
  text-align: center;
  margin: 40px 0 108px 0;
`;

const Inner = styled(InnerLayout)``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${(props) => props.theme.mobile} {
    gap: 40px;
    flex-direction: column;
  }
`;

const Title = styled.h3`
  font-size: 30px;
  line-height: 38px;
  font-weight: 700;
  @media ${(props) => props.theme.mobile} {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
`;

const FranchiseeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 56px 20px;
  margin-top: 38px;
  @media ${(props) => props.theme.mobile} {
    gap: 32px 15px;
  }
`;

const AddButton = styled.div`
  margin-top: 75px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  color: #4d4d4d;
  cursor: pointer;
  @media ${(props) => props.theme.mobile} {
    margin-top: 40px;
    font-size: 18px;
    line-height: 24px;
  }
`;

export default FranchiseeContent;
