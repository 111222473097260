import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet, useLocation } from 'react-router';

import mHomeBanner from 'assets/bg/mobile/m_homeBanner.png';
import mHomeBannerWebp from 'assets/bg/mobile/m_homeBanner.webp';
import homeBanner from 'assets/bg/pc/homeBanner.png';
import homeBannerWebp from 'assets/bg/pc/homeBanner.webp';
import BrowserTitle from 'components/_common/BrowserTitle';
import MainContainerLayout from 'components/_common/MainContainerLayout';
import TopBanner from 'components/_common/TopBanner';
import { checkWebpSupport } from 'utils/checkWebpSupport';

function Service() {
  const { state } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state]);

  return (
    <MainContainerLayout>
      <BrowserTitle
        title='KTP 케이티피 한국 택스리펀'
        description='스마트 텍스프리 서비스 KTP(케이티피), 이용료 없이 지금 바로 사용해보세요.'
        url='https://www.ktaxpay.com/service'
      />
      <TopBanner
        title={state ? state.detail.title : 'KTP 서비스 블로그'}
        content={
          state
            ? state.detail.subTitle
            : '매출을 올리는 똑똑한 변화,\n KTP의 이야기를 담았습니다.'
        }
        imgUrl={
          state
            ? state.detail.bannerBg
            : isMobile
              ? checkWebpSupport(mHomeBannerWebp, mHomeBanner)
              : checkWebpSupport(homeBannerWebp, homeBanner)
        }
      />
      <Outlet />
    </MainContainerLayout>
  );
}

export default Service;
