import KTPService from 'components/Medical/KTPService';
import KTPTaxRefund from 'components/Medical/KTPTaxRefund';
import KTPTaxRefunGlobalPay from 'components/Medical/KTPTaxRefunGlobalPay';
import VideoBanner from 'components/Medical/VideoBanner';
import BrowserTitle from 'components/_common/BrowserTitle';
import MainContainerLayout from 'components/_common/MainContainerLayout';
import RefundAplication from 'components/_common/RefundAplication';
import KTPIntroProcess from 'components/Medical/KTPIntroProcess';
import QuestionList from 'components/Medical/QuestionList';
import CloseBox from 'components/Medical/CloseBox';
import CustomerGraph from 'components/Medical/CustomerGraph';
import styled from 'styled-components';
import ServiceIntroduce from 'components/Medical/ServiceIntroduce';
import WithKTP from 'components/Medical/WithKTP';
import InquiryGuide from 'components/Medical/InquiryGuide';

function Medical() {
  return (
    <MainContainerLayout>
      <BrowserTitle
        title='KTP 케이티피 한국 택스리펀'
        description='스마트 텍스프리 서비스 KTP(케이티피), 이용료 없이 지금 바로 사용해보세요.'
        url='https://www.ktaxpay.com/medical'
      />
      <VideoBanner />
      <Wrapper>
        <CustomerGraph />
        <ServiceIntroduce />
        <WithKTP />
      </Wrapper>
      <InquiryGuide />
      <QuestionList />
      <CloseBox />
      <RefundAplication />
    </MainContainerLayout>
  );
}

export default Medical;

const Wrapper = styled.div`
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 5.75rem 0.75rem 10rem;
  gap: 7.5rem;
  @media ${(props) => props.theme.tablet} {
    padding: 2rem 1.25rem 3.5rem;
    gap: 2rem;
  }
  @media ${(props) => props.theme.mobile} {
    padding: 1.5rem 1.25rem 3.5rem;
    gap: 2.5rem;
  }
`;
