import { legacy_createStore as createStore } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';
import { persistStore, persistReducer } from 'redux-persist';

const initialState = {
  passportNumber: null,
  refundStatus: null,
  refundAfter: true,
  printData: null,
  visitedInfo: null,
};
const persistConfig = {
  key: 'root',
  storage: storageSession,
};
export function reducer(state = initialState, action: any) {
  switch (action.type) {
    case 'UPDATE_USER_INFO':
      return { ...state, ...action.payload };
    case 'VISITED_INFO':
      return { ...state, visitedInfo: action.payload };
    default:
      return state;
  }
}
const perReducer = persistReducer(persistConfig, reducer);
export const store = createStore(perReducer);
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
