import axios from 'axios';
import { axiosRequestConfiguration } from './config';
import { IApplicationFormValues, IEmailFormValues } from 'model/types';

const axiosInstance = axios.create(axiosRequestConfiguration);

const post = async <ReturnType, BodyType, ParamType>(
  url: string,
  body: BodyType,
  queryParams?: ParamType,
) =>
  axiosInstance.post<ReturnType>(url, body, {
    ...(queryParams && { params: queryParams }),
  });

const get = async <ReturnType, ParamType>(
  url: string,
  queryParams?: ParamType,
) =>
  axiosInstance.get<ReturnType>(url, {
    ...(queryParams && { params: queryParams }),
  });

export const applyFranchisee = async (payload: IApplicationFormValues) => {
  const res = await post<null, IApplicationFormValues, null>(
    '/franchisee-applicants/register',
    payload,
  );
  return res.data;
};
export const postEmail = async (payload: IEmailFormValues) => {
  const res = await post<void, IEmailFormValues, null>('/manual', payload);
  return res.data;
};
export const countFileDownload = async () => {
  const res = await get<null, null>('/manual');
  return res.status;
};
