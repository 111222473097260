import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { refundRoutes, routes, serviceJoinRoutes } from 'routes';
import styled, { css } from 'styled-components';

import Logo from 'components/_common/Logo';
import MenuIcon from 'assets/common/menu.png';
import WhiteMenuIcon from 'assets/common/whiteMenu.png';
import CloseIcon from 'assets/common/close.png';
import WhiteCloseIcon from 'assets/common/whiteClose.png';
import SubMenu from './SubMenu';
import FlexWrap from './FlexWrap';
import useDeviceCheck from 'hooks/useDeviceCheck';
import { useDispatch } from 'react-redux';

interface IMenuProps {
  isShow: boolean;
}

interface IProps {
  isTop: boolean;
  isBackground: boolean;
}
function Header({ isTop, isBackground }: IProps) {
  const dispatch = useDispatch();
  const { isTablet } = useDeviceCheck();
  const [isShow, setIsShow] = useState(false);
  const [isNavScroll, setIsNavScroll] = useState(true);
  const [isVideoBannerPass, setIsVideoBannerPass] = useState(false);
  let lastScroll = 0;

  const handleSetVisitedInfo = (pathname: string) => {
    dispatch({ type: 'VISITED_INFO', payload: pathname });
  };

  const links = [
    {
      text: '서비스 안내',
      link: routes.refund,
      pathname: '/refund',
    },
    ...(isTablet
      ? [
          {
            text: '매장 환급',
            link: refundRoutes.taxRefund,
            pathname: '/refund',
            isSubLink: true,
          },
          {
            text: '병원 환급',
            link: refundRoutes.medical,
            pathname: '/refund/medical',
            isSubLink: true,
          },
          {
            text: '해외 간편 결제',
            link: refundRoutes.globalPayment,
            pathname: '/refund/global-payment',
            isSubLink: true,
          },
        ]
      : []),
    {
      text: '서비스 가입',
      link: routes.serviceJoin,
      pathname: '/service-join',
    },
    ...(isTablet
      ? [
          {
            text: '직접 신청',
            link: serviceJoinRoutes.serviceJoin,
            pathname: '/service-join',
            isSubLink: true,
          },
          {
            text: 'KTP 대리 신청',
            link: serviceJoinRoutes.ktp,
            pathname: '/service-join/ktp',
            isSubLink: true,
          },
        ]
      : []),
    {
      text: '자주 찾는 질문',
      link: routes.faq,
      pathname: '/faq',
    },
    {
      text: '체험해보기',
      link: routes.trial,
      pathname: '/trial',
    },
    {
      text: '기업 맞춤형 서비스 신청',
      externalLink: 'https://api.ktaxpay.com/',
    },
  ];
  const { pathname } = useLocation();

  const checkMovieBannerURL = ['/medical', '/'].includes(pathname);

  const onToggleMenu = useCallback(() => {
    setIsShow((prevState) => !prevState);
  }, []);

  const handleScroll = () => {
    const homeBanner = document.querySelector('#home-banner');
    if (!homeBanner) return;
    const bannerHeight = homeBanner.clientHeight;
    if (isTablet) return;

    let currScroll = window.scrollY;
    if (currScroll > bannerHeight) {
      setIsNavScroll(false);
      if (currScroll < lastScroll) {
        setIsNavScroll(true);
      }
      lastScroll = currScroll;
    } else {
      setIsNavScroll(true);
    }
  };

  useEffect(() => {
    if (['/hotel', '/medical', '/'].includes(pathname)) {
      handleSetVisitedInfo(pathname);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMedicalScroll = () => {
    const videoBanner = document.querySelector('#video-banner');
    if (!videoBanner) return;
    const bannerHeight = videoBanner.clientHeight;

    if (window.scrollY > bannerHeight) {
      setIsVideoBannerPass(true);
    } else {
      setIsVideoBannerPass(false);
    }
  };

  useEffect(() => {
    if (pathname === '/medical' || pathname === '/') {
      window.addEventListener('scroll', handleMedicalScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleMedicalScroll);
    };
  }, []);

  const showMenuStyle = () => {
    let close = CloseIcon;
    let menu = MenuIcon;

    if (checkMovieBannerURL) {
      if (isVideoBannerPass) {
        close = CloseIcon;
        menu = MenuIcon;
      } else {
        close = WhiteCloseIcon;
        menu = WhiteMenuIcon;
      }
    }

    return isShow ? close : menu;
  };

  return (
    <Container
      isTop={isTop}
      isNavScroll={isNavScroll}
      checkMovie={checkMovieBannerURL}
    >
      <Inner>
        <LogoWrapper checkMovie={checkMovieBannerURL}>
          <Logo
            isTop={['/', '/1', '/hotel'].includes(pathname) ? false : isTop}
          />
        </LogoWrapper>
        <Menu
          alt='메뉴.'
          src={showMenuStyle()}
          width='20px'
          height='20px'
          onClick={onToggleMenu}
        />
        <Navi isShow={isShow}>
          <LinkList>
            {links.map((item, index) => {
              if (!isTablet && item.externalLink) return;
              return (
                <ListItem key={index}>
                  {item?.link ? (
                    <Link to={item.link}>
                      <CustomLink
                        data-route={item.link}
                        active={pathname.includes(item.pathname)}
                        //@ts-ignore
                        isSubLink={item?.isSubLink}
                        checkMovie={checkMovieBannerURL}
                        isVideoBannerPass={isVideoBannerPass}
                        onClick={onToggleMenu}
                      >
                        {item.text}
                      </CustomLink>
                    </Link>
                  ) : (
                    <ExternalLink href={item.externalLink} target='_blank'>
                      {item.text}
                    </ExternalLink>
                  )}

                  {(item.pathname === '/refund' ||
                    item.pathname === '/service-join') &&
                    !isMobile && (
                      <SubMenu
                        itemPathname={item.pathname}
                        checkVideo={checkMovieBannerURL}
                        isVideoBannerPass={isVideoBannerPass}
                      />
                    )}
                </ListItem>
              );
            })}
          </LinkList>

          {!isTablet && (
            <FlexWrap
              alignItems='center'
              flex='1'
              justifyContent='flex-end'
              gap='1rem'
            >
              <ContectText
                checkMovie={checkMovieBannerURL}
                isVideoBannerPass={isVideoBannerPass}
              >
                문의 02-6275-8011
              </ContectText>
              <ExternalLink
                isButton
                href='https://api.ktaxpay.com/'
                target='_blank'
              >
                기업 맞춤형 서비스 신청
              </ExternalLink>
            </FlexWrap>
          )}
        </Navi>
      </Inner>
    </Container>
  );
}

const Container = styled.header<{
  isTop: boolean;
  isNavScroll: boolean;
  checkMovie: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 25;
  transition: background-color 300ms ease-in-out;
  font-weight: bold;
  padding: 0 40px;
  background-color: ${(props) => (props.checkMovie ? 'none' : ' #fff')};
  backdrop-filter: blur(3px);
  color: white;
  border-bottom: ${(props) =>
    props.checkMovie ? 'none' : ' 1px solid #edeeef'};
  @media ${(props) => props.theme.desktop} {
    height: 70px;
  }
  @media ${(props) => props.theme.tablet} {
    height: 50px;
    padding: 0;
  }
  transform: ${(props) =>
    props.isNavScroll ? 'translateY(0px)' : 'translateY(-80px)'};
  transition: all 300ms ease-in;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  @media ${(props) => props.theme.tablet} {
    flex-direction: column;
    padding: 0;
  }
`;

const LogoWrapper = styled.div<{ checkMovie: boolean }>`
  flex: 1;
  @media ${(props) => props.theme.tablet} {
    padding: 14px 20px;
    width: 100%;
    height: 100%;
    border-bottom: ${(props) =>
      props.checkMovie ? 'none' : ' 1px solid #cccccc'};
  }
`;

const Navi = styled.nav<IMenuProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 3;
  @media ${(props) => props.theme.tablet} {
    width: 100%;
    z-index: 20;
    display: none;
    ${(props) =>
      props.isShow &&
      css`
        display: block;
      `}
  }
`;

const LinkList = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  flex: 1;

  @media ${(props) => props.theme.desktop} {
    align-items: center;
    height: 60px;
  }
  @media ${(props) => props.theme.tablet} {
    display: block;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
  }
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  line-height: 23px;
  height: 100%;
  position: relative;
  :hover .subMenu {
    pointer-events: auto;
    .menu_bg {
      height: 60px;
      border-top: 1px solid #cccccc;
    }
    a {
      height: 56px;
    }
  }
  @media ${(props) => props.theme.tablet} {
    display: block;
    background-color: #fff;
    width: 101%;
    margin-left: -1px;
    & a {
      display: block;
      height: 100%;
    }
  }
`;

const CustomLink = styled.div<{
  active: boolean;
  isSubLink?: boolean;
  checkMovie: boolean;
  isVideoBannerPass: boolean;
}>`
  transition: all 300ms ease-in;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) =>
    props.checkMovie && !props.isVideoBannerPass ? '#ffffff' : ' #030303'};
  white-space: nowrap;
  padding: 12px 10px;
  @media ${(props) => props.theme.tablet} {
    color: #030303;
    font-size: 14px;
    line-height: 21px;
    padding-left: 20px;
    ${(props) =>
      props.isSubLink &&
      css`
        background-color: #f5f6f7;
        color: #3a3b3e;
        font-weight: 400;
        padding-left: 30px;
      `}
  }
`;
const ExternalLink = styled.a<{ isButton?: boolean }>`
  transition: all 300ms ease-in;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 14px;
  font-weight: bold;
  color: ${(props) => (props.isButton ? '#FFF' : '#030303')};
  border-radius: 12px;
  background-color: ${(props) => (props.isButton ? '#246cf6' : 'transparent')};
  @media ${(props) => props.theme.tablet} {
    font-size: 14px;
    line-height: 21px;
    margin-left: 6px;
  }
`;
const Menu = styled.img`
  display: none;
  @media ${(props) => props.theme.tablet} {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 10px;
    transform: translate(-50%, -50%);
  }
`;
const ContectText = styled.span<{
  checkMovie: boolean;
  isVideoBannerPass: boolean;
}>`
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  color: ${(props) =>
    props.checkMovie && !props.isVideoBannerPass ? '#ffffff' : '#246cf6'};
  transition: all 300ms ease-in;
`;
export default React.memo(Header);
