import React, { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';

import Logo from 'components/_common/Logo';
import Menu from 'assets/common/menu.png';
import Close from 'assets/common/close.png';
import { routes } from 'routes';
import homeIcon from 'assets/trial/home.png';
import leftArrowIcon from 'assets/trial/leftArrow.png';
import alarmIcon from 'assets/trial/alarm.png';
import Icon from 'components/_common/Icon';
import useTrialActions from 'hooks/useTrialActions';
import LogoImg from 'assets/common/ktp_logo.png';
import FlexWrap from 'components/_common/FlexWrap';

interface IMenuProps {
  isShow: boolean;
}

interface IListItemProps {
  idx: number;
}

const links = [
  {
    text: 'TAX FREE란?',
    link: routes.refund,
    pathname: '/refund',
  },
  {
    text: '자주 찾는 질문',
    link: routes.faq,
    pathname: '/faq',
  },
  {
    text: '체험해보기',
    link: routes.trial,
    pathname: '/trial',
  },
  {
    text: '도입 문의',
    link: routes.application,
    pathname: '/application',
  },
];

type phoneHeaderProps = {
  isPayment?: boolean;
  isLogo?: boolean;
  keyword: string;
};

function PhoneHeader({ isPayment, isLogo, keyword }: phoneHeaderProps) {
  const [isShow, setIsShow] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { onBack, onHome } = useTrialActions();

  const onToggleMenu = useCallback(() => {
    setIsShow((prevState) => !prevState);
  }, []);

  const onToggleDown: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      const route = e.currentTarget.dataset.route;
      setIsShow(false);
      if (route) {
        navigate(route);
      } else {
        navigate(routes.home);
      }
    },
    [navigate],
  );

  const selectHeader = (value: string) => {
    switch (value) {
      case 'HOME':
        return (
          <>
            <PcHeaderText>KTP</PcHeaderText>
            <HeaderRightIcon
              width='24px'
              height='24px'
              alt='알림.'
              src={alarmIcon}
              style={{ cursor: 'auto' }}
            />
          </>
        );
      case 'SCAN':
        return (
          <Header>
            <HeaderLeftIcon
              width='100px'
              height='26px'
              alt='알림.'
              src={LogoImg}
              style={{ cursor: 'auto' }}
            />
            <HeaderRightIcon
              width='24px'
              height='24px'
              alt='알림.'
              src={Menu}
              style={{ cursor: 'auto' }}
            />
          </Header>
        );
      case 'PAYMENT':
        return (
          <Header>
            <Button onClick={onBack}>
              <Icon imgUrl={leftArrowIcon} width='24px' height='24px' />
            </Button>
            <PcHeaderText>상품금액 입력</PcHeaderText>{' '}
            <Button onClick={onHome}>
              <Icon imgUrl={homeIcon} width='24px' height='24px' alt='KTP.' />
            </Button>
          </Header>
        );
      case 'REFUND_COMPLETE':
        return (
          <>
            <PcHeaderText>환급완료</PcHeaderText>
            <Button onClick={onHome}>
              <HeaderRightIcon
                width='24px'
                height='24px'
                alt='알림.'
                src={homeIcon}
              />
            </Button>
          </>
        );
    }
  };

  return (
    <Container isTop={false}>
      <Inner>
        {isMobile ? (
          <>
            <LogoWrapper>
              <Logo isTop={false} isMobile />
            </LogoWrapper>{' '}
            <HeaderRightIcon
              width='24px'
              height='24px'
              alt='메뉴.'
              src={isShow ? Close : Menu}
              onClick={onToggleMenu}
            />
          </>
        ) : (
          selectHeader(keyword)
        )}
        <Navi isShow={isShow}>
          <LinkList>
            {links.map((item, index) => {
              return (
                <ListItem key={index} idx={index}>
                  <CustomLink
                    onClick={onToggleDown}
                    data-route={item.link}
                    active={item.pathname === pathname}
                    isTop={false}
                  >
                    {item.text}
                  </CustomLink>
                </ListItem>
              );
            })}
          </LinkList>
        </Navi>
      </Inner>
    </Container>
  );
}
const Header = styled(FlexWrap)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
`;
const PcHeaderText = styled.h3`
  text-align: center;
  padding: 17px 0;
  font-size: 18px;
`;
const Container = styled.header<{ isTop: boolean }>`
  position: fixed;
  top: 0;
  z-index: 12;
  height: 50px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #cccccc;
  transition: 300ms all ease-in-out;
  ${(props) =>
    props.isTop &&
    css`
      background-color: transparent;
      color: white;
      border: none;
    `}
  border-radius: 15px 15px 0 0;
  top: -8px;
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1190px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  flex-direction: column;
  padding: 0;
`;

const LogoWrapper = styled.div`
  padding: 14px 16px;
  width: 100%;
  height: 100%;
`;

const Navi = styled.nav<IMenuProps>`
  display: flex;
  align-items: center;
  width: 100%;
  width: 100%;
  z-index: 20;
  display: none;
  ${(props) =>
    props.isShow &&
    css`
      display: block;
    `}
`;

const LinkList = styled.ul`
  margin: 0 auto;
  display: block;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
`;

const ListItem = styled.li<IListItemProps>`
  line-height: 23px;
  padding: 16px 14px;
  height: 100%;
  cursor: pointer;
  display: block;
  background-color: #fff;
  width: 101%;
  margin-left: -1px;
  & a {
    padding: 16px 14px;
    display: block;
    height: 100%;
  }
`;

const CustomLink = styled.div<{ active: boolean; isTop: boolean }>`
  transition: all 300ms ease-in;
  color: ${(props) =>
    props.isTop
      ? 'white'
      : props.active
        ? props.theme.primaryTextColor
        : 'black'};
  font-size: 16px;
  line-height: 23px;
`;

const HeaderLeftIcon = styled.img`
  cursor: pointer;
  position: absolute;
  top: 25px;
  left: 20px;
  transform: translate(0%, -50%);
`;
const HeaderRightIcon = styled.img`
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 20px;
  transform: translate(-50%, -50%);
`;
const Button = styled.button``;
export default React.memo(PhoneHeader);
